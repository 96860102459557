import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React from 'react';
import styled from 'styled-components';

import * as Util from '../util';

import { FullBleedButton } from './FullBleedButton';

const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const GuidanceCard = styled(Card)`
  background-color: #359a230f;
`;

const CardsHeader = styled(Typography)`
  padding: 0.5rem 1rem;
`;

const Text = styled(Typography)`
  padding: 1rem;
`;

export const ChromeExtensionGuidanceCard: React.FC = () => {
  return (
    <Content>
      <GuidanceCard variant="outlined">
        <CardsHeader color="textSecondary">
          Install Chrome Extension of PFN Paper Curation Project
        </CardsHeader>
        <Text color="textPrimary">
          Our Chrome extension allows you to favorite and comment on papers on arxiv. Also, these
          information are aggregated in this portal so that you can check them later.
        </Text>
        <CardActions>
          <FullBleedButton
            color="primary"
            endIcon={<ArrowForwardIcon />}
            onClick={(): void => Util.openChromeExtensionPage()}
          >
            Install Now
          </FullBleedButton>
        </CardActions>
      </GuidanceCard>
    </Content>
  );
};

import React from 'react';
import styled from 'styled-components';

import { Scalars } from '../hooks/query';
import { useUserStatistics } from '../hooks/user';

import { StatsChart } from './StatsChart';

const Content = styled.div`
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const StatsChartWrap = styled.div`
  width: 50%;

  &:first-child {
    margin-right: 10px;
  }
`;

export const StatsCharts: React.FC<{ userId: Scalars['ID'] }> = ({ userId }) => {
  const { data } = useUserStatistics({ userId });

  return (
    <Content>
      <Row>
        {data?.reads && (
          <StatsChartWrap>
            <StatsChart title="Read" data={data.reads} />
          </StatsChartWrap>
        )}
        {data?.readLaters && (
          <StatsChartWrap>
            <StatsChart title="Read Later" data={data.readLaters} />
          </StatsChartWrap>
        )}
      </Row>
      <Row>
        {data?.comments && (
          <StatsChartWrap>
            <StatsChart title="Comments" data={data.comments} />
          </StatsChartWrap>
        )}
        {data?.favorites && (
          <StatsChartWrap>
            <StatsChart title="Favorites" data={data.favorites} />
          </StatsChartWrap>
        )}
      </Row>
    </Content>
  );
};

import styled from 'styled-components';

export const NumberBadge = styled.span<{
  color?: string;
  backgroundColor?: string;
  fontSize?: string;
}>`
  display: inline-block;
  font-size: ${({ fontSize = '0.9rem' }): string => fontSize};
  color: ${({ color = '#4c4c4c' }): string => color};
  background-color: ${({ backgroundColor = '#f5f5f5' }): string => backgroundColor};
  padding: 0 9px;
  border-radius: 6px;
  margin: 0 0 0 0.5rem;
`;

import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { useMyAccessLogsQuery } from '../hooks/query';
import { MyAccessLogType } from '../types/GraphQL';

import { AccessedPaperCard } from './AccessedPaperCard';
import { Cards } from './Cards';
import { FullBleedButton } from './FullBleedButton';

const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto 20px;
`;

export const AccessedPaperCards: React.FC<{
  perPage?: number;
  offActions?: boolean;
}> = ({ perPage = 3, offActions = false }) => {
  const history = useHistory();
  const [cursor, setCursor] = React.useState<string | undefined>();
  const [loadedAccessLogs, setLoadedAccessLogs] = React.useState<MyAccessLogType[]>([]);
  const { loading, data } = useMyAccessLogsQuery({
    variables: {
      pagingInput: {
        first: perPage,
        after: cursor,
      },
    },
  });

  const nextCursor = data?.me.accessLogs.pageInfo.endCursor;
  const accessLogs = data?.me.accessLogs.edge ?? [];
  const hasData = accessLogs.length > 0;

  const load = React.useCallback(() => {
    if (nextCursor) setCursor(nextCursor);
  }, [nextCursor]);

  React.useEffect(() => {
    setLoadedAccessLogs([...loadedAccessLogs, ...accessLogs]);
  }, [accessLogs]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Content>
      <Cards
        header="Your access log"
        loading={loading && !hasData}
        hasData={hasData}
        noDataText="No logs"
        loadingSkeletonSize={perPage}
        load={offActions ? load : undefined}
        last={!nextCursor && offActions}
      >
        {loadedAccessLogs.map((accessLog) => (
          <div key={accessLog.id}>
            <AccessedPaperCard paperAccessLog={accessLog} />
            <Divider />
          </div>
        ))}

        {!offActions && hasData && (
          <CardActions>
            <FullBleedButton
              color="primary"
              endIcon={<ArrowForwardIcon />}
              onClick={(): void => history.push('/me/accesslogs')}
            >
              All access logs
            </FullBleedButton>
          </CardActions>
        )}
      </Cards>
    </Content>
  );
};

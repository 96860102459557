import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import React from 'react';
import styled from 'styled-components';

import { AnchorA } from './Anchor';

const A = styled(AnchorA)`
  margin-left: 10px;

  & svg {
    vertical-align: text-top;
    font-size: 1em;
  }
`;

export const PdfAnchor: React.FC<{
  url: string;
}> = ({ url }) => (
  <>
    {url && (
      <A href={url} target="_blank" rel="noopener noreferrer">
        <PictureAsPdfIcon />
      </A>
    )}
  </>
);

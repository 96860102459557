import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { UserContext } from '../context';
import { useAuth } from '../hooks/auth';
import logoWhite from '../logo_white.png';
import * as Util from '../util';

const Logo = styled(Typography)`
  margin-top: 2px;
` as typeof Typography;

const LogoLink = styled.div`
  width: 180px;

  cursor: pointer;
  &:hover,
  &:focus,
  &:active {
    color: #ddd;
  }
`;

const LogoImage = styled.img`
  padding: 2px;
  width: 31px;
  float: left;
  margin-right: 8px;
`;

const LogoText = styled.div`
  width: 180px;
`;

const AppNavBar = styled(AppBar)``;

const TopToolbar = styled(Toolbar)`
  height: 49px;
`;

const ToolbarLeftSection = styled.div`
  display: flex;
  flex: 1;
`;

const SignOutMenuItem = styled(MenuItem)`
  justify-content: center;
`;

const EmailAddress = styled.div`
  padding: 15px;
`;

const NavigationLink = styled.div`
  padding: 9px 0;
  margin: 0 10px;
  font-size: 1rem;
  cursor: pointer;
  font-weight: bold;

  &:hover,
  &:focus,
  &:active {
    color: #ddd;
  }
`;

const NavigationIcon = styled.i`
  margin: 0 7px 0 5px;
`;

const AccountMenu: React.FC = () => {
  const { session } = React.useContext(UserContext);
  const { signOut } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <IconButton color="inherit" onClick={handleMenu}>
        <AccountCircle />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem>
          <EmailAddress>{session?.email}</EmailAddress>
        </MenuItem>
        <SignOutMenuItem onClick={signOut}>
          <Typography color="primary">Sign Out</Typography>
        </SignOutMenuItem>
      </Menu>
    </>
  );
};

export const Navigation: React.FC = () => {
  const history = useHistory();

  return (
    <AppNavBar position="fixed">
      <TopToolbar variant="dense">
        <ToolbarLeftSection>
          <Logo variant="h6" component="div">
            <LogoLink onClick={(): void => history.push('/')}>
              <LogoImage src={logoWhite} alt="Preferred Network Logo Image" />
              <LogoText>Paper Curation</LogoText>
            </LogoLink>
          </Logo>
          <NavigationLink onClick={(): void => history.push('/rankings')}>Rankings</NavigationLink>
          <NavigationLink onClick={(): void => history.push('/tags')}>Tags</NavigationLink>
        </ToolbarLeftSection>
        <Hidden xsDown>
          <NavigationLink onClick={(): void => Util.openChromeExtensionPage()}>
            <NavigationIcon className="fab fa-chrome" />
            Chrome Extension
          </NavigationLink>
          <NavigationLink onClick={(): void => Util.openGithubPage()}>
            <NavigationIcon className="fab fa-github" />
            Github
          </NavigationLink>
        </Hidden>
        <AccountMenu />
      </TopToolbar>
    </AppNavBar>
  );
};

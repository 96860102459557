import { Typography, CardActionArea } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { PaperFull } from '../hooks/query';
import { toPapers } from '../routing';

import { PaperCardContent } from './PaperCardContent';

const CardInner = styled.div`
  display: flex;
  align-items: center;
  padding-top: 8px;

  & .MuiCardContent-root:last-child {
    padding: 7px;
  }
`;

const Rank = styled.div<{ rank: number }>`
  text-align: center;
  width: 60px;
  margin-left: 0.3rem;
  font-size: ${({ rank }): string => {
    if (rank <= 3) return '26px';
    return '20px';
  }};
  font-weight: ${({ rank }): string => {
    if (rank <= 3) return 'bold';
    return 'normal';
  }};
  color: ${({ rank }): string => {
    if (rank === 1) return '#d8d100';
    if (rank === 2) return '#bfbfbf';
    if (rank === 3) return '#cc842b';
    return 'inherit';
  }};
`;

const Score = styled(Typography)`
  font-size: 11px;
`;

export const RankingPaperCard: React.FC<{
  rank: number;
  score: number;
  paperFull: Pick<PaperFull, 'paperSources' | 'tags'>;
  truncatedLimit?: number;
}> = ({ rank, score, paperFull, truncatedLimit = 200 }) => {
  const history = useHistory();
  const { paperSources, tags } = paperFull;
  const paper = paperSources?.[0];
  return paper ? (
    <div>
      <CardActionArea
        onClick={(event): void => {
          const target = event.target as HTMLElement;
          if (target.tagName !== 'A')
            history.push(toPapers(paper.source, paper.originalID, paper.version));
        }}
      >
        <CardInner>
          <Rank rank={rank}>
            {rank}
            <Score color="textSecondary">{score}pt</Score>
          </Rank>
          <PaperCardContent noAction paper={paper} tags={tags} truncatedLimit={truncatedLimit} />
        </CardInner>
      </CardActionArea>
    </div>
  ) : (
    <div>Paper not found</div>
  );
};

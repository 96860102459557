import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Chip,
  DialogContentText,
  DialogContent,
} from '@material-ui/core';
import GroupIcon from '@material-ui/icons/Group';
import SecurityIcon from '@material-ui/icons/Security';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Tag as TTag } from '../hooks/query';
import { normalizeTagName } from '../util';
import * as Util from '../util';

type TagType = 'user' | 'shared' | 'team';

const TagChip = styled(Chip)`
  padding: 0 4px;
`;

export const getTagType = (tagName: string): TagType => {
  if (tagName.startsWith('user:')) return 'user';
  if (tagName.startsWith('team:')) return 'team';
  return 'shared';
};

export const Tag: React.FC<{
  tagName: TTag['name'];
  color?: 'default' | 'primary' | 'secondary';
  openLinkNewTab?: boolean;
  onDelete?: (tagname: TTag['name']) => void;
  onClick?: (tagName: TTag['name']) => void;
}> = ({ tagName, color = 'default', openLinkNewTab = false, onClick, onDelete }) => {
  const history = useHistory();

  const type = getTagType(tagName);

  const [open, setOpen] = React.useState(false);
  const normalizedTagName = normalizeTagName(tagName);

  const close = React.useCallback(() => {
    setOpen(false);
  }, []);

  const openTagPapers = React.useCallback(() => {
    if (openLinkNewTab) {
      Util.openPortal(`/tags/${tagName}/papers`);
    } else {
      history.push(`/tags/${tagName}/papers`);
    }
  }, [history, openLinkNewTab, tagName]);

  return (
    <>
      <TagChip
        key={tagName}
        size="small"
        color={color}
        label={normalizedTagName}
        variant="outlined"
        onClick={(): void => (onClick ? onClick(tagName) : openTagPapers())}
        icon={type === 'user' ? <SecurityIcon /> : type === 'team' ? <GroupIcon /> : undefined}
        onDelete={onDelete ? (): void => setOpen(true) : undefined}
      />
      <Dialog open={open} onClose={close} aria-labelledby="add-tag-dialog-title">
        <DialogTitle id="add-tag-dialog-title">Delete a tag</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this tag.</DialogContentText>
          <TagChip
            key={tagName}
            icon={type === 'user' ? <SecurityIcon /> : type === 'team' ? <GroupIcon /> : undefined}
            size="small"
            label={normalizedTagName}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary">
            Cancel
          </Button>
          <Button
            onClick={(): void => {
              if (onDelete) onDelete(tagName);
              setOpen(false);
            }}
            color="primary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

import MUIBreadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import { DateTime } from 'luxon';
import qs from 'qs';
import React from 'react';
import DatePicker from 'react-datepicker';
import { useHistory, useParams } from 'react-router-dom';
import useMeasure from 'react-use-measure';
import {
  AreaChart,
  Area,
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  BarChart,
  Bar,
} from 'recharts';
import styled from 'styled-components';

import {
  DateRange,
  StatisticsData,
  useUserDateStatistics,
  useUserProfile,
  useUserStatistics,
} from '../hooks/user';
import { theme as Theme } from '../theme';
import { numberToId } from '../util';

import { Anchor } from './Anchor';

const Content = styled.div`
  max-width: 1200px;
  margin: 0px auto;
  padding-top: 18px;
`;

const Breadcrumbs = styled(MUIBreadcrumbs)`
  margin: 0 2px 0.5rem;
`;

const CardHeader = styled.div`
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
`;

const UserName = styled.span`
  font-weight: bold;
  color: ${Theme.palette.secondary.main};
`;

const HeaderConfig = styled.div`
  display: flex;
`;

const ChartTypeSelect = styled(Select)`
  margin-right: 1rem;
`;

const DatePickers = styled.div`
  display: flex;
  align-items: center;

  & input {
    border: 1px solid ${Theme.palette.grey['300']};
    border-radius: 4px;
    padding: 5px 0.5rem;
    color: ${Theme.palette.grey['800']};
  }
`;

const DatePickerDivider = styled.div`
  margin: 0 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  color: ${Theme.palette.grey['600']};
`;

const CardInner = styled.div`
  padding: 1rem;
`;

const Stats = styled.div`
  display: flex;
`;

const StatContent = styled.div`
  padding: 1rem;
  width: 100%;
  margin-left: 1rem;
  background-color: #f8f8f8;

  &:first-child {
    margin-left: 0;
  }
`;

const StatName = styled.div`
  color: #8e8e8e;
  font-weight: bold;
  font-size: 1.1rem;
`;

const StatValue = styled.div`
  width: 100%;
  color: #585858;
  font-weight: bold;
  font-size: 3rem;
  text-align: right;
`;

const StatChartContent = styled.div`
  margin: 1rem 0;
`;

export const Stat: React.FC<{
  name: string;
  value?: number;
}> = ({ name, value }) => (
  <StatContent>
    <StatName>{name}</StatName>
    <StatValue>{value ?? '-'}</StatValue>
  </StatContent>
);

const ChartType = ['bar', 'histogram', 'area'] as const;
type ChartType = typeof ChartType[number];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isChartType = (value: any): value is ChartType => ChartType.includes(value);
const ChartTypeComponentMap = {
  bar: BarChart,
  area: AreaChart,
  histogram: AreaChart,
};
const ChartComponentMap = {
  bar: Bar,
  area: Area,
  histogram: Area,
};
export const StatChart: React.FC<{
  name: string;
  data: StatisticsData;
  dateRange: DateRange;
  chartType: ChartType;
}> = ({ name, data, dateRange, chartType }) => {
  const [ref, { width }] = useMeasure();
  const value = useUserDateStatistics({ data, dateRange, cumulative: chartType === 'histogram' });
  const ChartTypeComponent = ChartTypeComponentMap[chartType];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ChartComponent: any = ChartComponentMap[chartType]; // FIXME

  return (
    <StatChartContent ref={ref}>
      <ChartTypeComponent
        width={width}
        height={230}
        data={value}
        margin={{
          top: 5,
          right: 0,
          left: -20,
          bottom: 20,
        }}
      >
        <Tooltip />
        <CartesianGrid strokeDasharray="3 3" />
        <Legend wrapperStyle={{ bottom: chartType === 'bar' ? 10 : 5 }} />
        <XAxis dataKey="date" interval="preserveStartEnd" minTickGap={20} />
        <YAxis dataKey="value" domain={['auto', 'auto']} />
        <ChartComponent
          type="monotone"
          name={name}
          dataKey="value"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...(chartType === 'histogram' ? { stroke: Theme.palette.primary.light } : {})}
          fill={Theme.palette.primary.light}
        />
      </ChartTypeComponent>
    </StatChartContent>
  );
};

export const Statistics: React.FC = () => {
  const history = useHistory();

  const params = qs.parse(document.location.search, {
    ignoreQueryPrefix: true,
  });
  const startDateFromParam = DateTime.fromFormat(
    typeof params?.start === 'string' ? params?.start : '',
    'yyyy-MM-dd'
  );
  const endDateFromParam = DateTime.fromFormat(
    typeof params?.end === 'string' ? params?.end : '',
    'yyyy-MM-dd'
  );
  const chartTypeFromParam = params?.chart ?? 'bar';

  const today = React.useMemo(() => DateTime.local(), []);

  const { userId } = useParams<{ userId: string }>();

  const range = 30;
  const defaultEndDate = React.useMemo(
    () => (endDateFromParam.isValid ? endDateFromParam : DateTime.local()),
    [endDateFromParam]
  );
  const defaultStartDate = React.useMemo(
    () => (startDateFromParam.isValid ? startDateFromParam : defaultEndDate.minus({ days: range })),
    [defaultEndDate, startDateFromParam]
  );

  const [chartType, setChartType] = React.useState<ChartType>(
    isChartType(chartTypeFromParam) ? chartTypeFromParam : 'bar'
  );
  const [startDate, setStartDate] = React.useState(defaultStartDate);
  const [endDate, setEndDate] = React.useState(defaultEndDate);

  const { data } = useUserStatistics({
    userId: numberToId('User', userId),
    dateRange: { start: startDate, end: endDate },
  });
  const { data: user } = useUserProfile({ userId: numberToId('User', userId) });

  React.useEffect(() => {
    const formattedStartDate = startDate.toFormat('yyyy-MM-dd');
    const formattedEndDate = endDate.toFormat('yyyy-MM-dd');
    if (
      formattedStartDate !== params?.start ||
      formattedEndDate !== params?.end ||
      chartType !== params?.chart
    ) {
      const search = `?start=${formattedStartDate}&end=${formattedEndDate}&chart=${chartType}`;
      history.replace({
        search,
      });
    }
  }, [chartType, endDate, history, params?.chart, params?.end, params?.start, startDate]);

  return (
    <Content>
      <Grid item xs={12} md={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Anchor to={`/dashboard/${userId}`}>{user?.profile.userName ?? '-'}</Anchor>
          <Typography color="textPrimary">statistics</Typography>
        </Breadcrumbs>
        <Card variant="outlined">
          <CardHeader>
            <Typography variant="subtitle1" color="textSecondary">
              Statistics of <UserName>{user?.profile.userName ?? ''}</UserName>
            </Typography>
            <HeaderConfig>
              <ChartTypeSelect
                value={chartType}
                onChange={(event) => setChartType(event.target.value as ChartType)}
              >
                <MenuItem value="bar">Bar Chart</MenuItem>
                <MenuItem value="area">Area Chart</MenuItem>
                <MenuItem value="histogram">Cumulative histogram</MenuItem>
              </ChartTypeSelect>
              <DatePickers>
                <DatePicker
                  dateFormat="yyyy/MM/dd"
                  selected={startDate.toJSDate()}
                  onChange={(date: Date) => setStartDate(DateTime.fromJSDate(date))}
                  selectsStart
                  startDate={startDate.toJSDate()}
                  endDate={endDate.toJSDate()}
                  maxDate={endDate.toJSDate()}
                />
                <DatePickerDivider>~</DatePickerDivider>
                <DatePicker
                  dateFormat="yyyy/MM/dd"
                  selected={endDate.toJSDate()}
                  onChange={(date: Date) => setEndDate(DateTime.fromJSDate(date))}
                  selectsEnd
                  startDate={startDate.toJSDate()}
                  endDate={endDate.toJSDate()}
                  minDate={startDate.toJSDate()}
                  maxDate={today.toJSDate()}
                />
              </DatePickers>
            </HeaderConfig>
          </CardHeader>
          <Divider />
          <CardInner>
            <Stats>
              <Stat name="Reads" value={data?.reads.length} />
              <Stat name="Read Laters" value={data?.readLaters.length} />
              <Stat name="Comments" value={data?.comments.length} />
              <Stat name="Favorites" value={data?.favorites.length} />
            </Stats>
            <StatChart
              chartType={chartType}
              name="reads"
              data={data?.reads ?? []}
              dateRange={{ start: startDate, end: endDate }}
            />
            <StatChart
              chartType={chartType}
              name="read laters"
              data={data?.readLaters ?? []}
              dateRange={{ start: startDate, end: endDate }}
            />
            <StatChart
              chartType={chartType}
              name="comments"
              data={data?.comments ?? []}
              dateRange={{ start: startDate, end: endDate }}
            />
            <StatChart
              chartType={chartType}
              name="favorites"
              data={data?.favorites ?? []}
              dateRange={{ start: startDate, end: endDate }}
            />
          </CardInner>
        </Card>
      </Grid>
    </Content>
  );
};

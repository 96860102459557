import React from 'react';
import CalendarHeatmap from 'react-calendar-heatmap';
import styled from 'styled-components';

import { Scalars, useUserStatsQuery } from '../hooks/query';
import { toDateString } from '../util';

const today = new Date();
const prevYear = new Date().setDate(today.getDate() - 182);

const Content = styled.div`
  height: 118px;

  & .react-calendar-heatmap .color-scale-1 {
    fill: #7e97e2;
  }
  & .react-calendar-heatmap .color-scale-2 {
    fill: #5072d7;
  }
  & .react-calendar-heatmap .color-scale-3 {
    fill: #2d54c8;
  }
  & .react-calendar-heatmap .color-scale-4 {
    fill: #23419a;
  }
`;

type Values = { date: string; count: number }[];

const aggregate = (values: Values): Values => {
  const agg = values.reduce<{ [K: string]: number }>(
    (prev, obj) => ({
      ...prev,
      [obj.date]: obj.date in prev ? prev[obj.date] + obj.count : obj.count,
    }),
    {}
  );
  return Object.entries(agg).map(([date, count]) => ({ date, count }));
};

export const UserCalendarHeatmap: React.FC<{ userId: Scalars['ID'] }> = ({ userId }) => {
  const { loading, error, data } = useUserStatsQuery({ variables: { id: userId } });
  const [values, setValues] = React.useState<Values>([]);

  React.useEffect(() => {
    if (!loading && !error && data?.user) {
      const favs = data.user.favorites.edge.map((i) => ({
        date: toDateString(i.createdAt),
        count: 1,
      }));
      const readLaters = data.user.readLaterPapers.edge.map((i) => ({
        date: toDateString(i.changedAt),
        count: 1,
      }));
      const comments = data.user.comments.edge.map((i) => ({
        date: toDateString(i.createdAt),
        count: 3,
      }));
      const reads = data.user.readPapers.edge.map((i) => ({
        date: toDateString(i.changedAt),
        count: 5,
      }));
      const newValues = aggregate([...favs, ...readLaters, ...comments, ...reads]);
      setValues(newValues);
    }
  }, [loading, error, data]);

  return (
    <Content>
      <CalendarHeatmap
        startDate={prevYear}
        endDate={today}
        classForValue={(value): string => {
          if (!value) {
            return 'color-empty';
          }
          if (value.count > 20) return 'color-scale-4';
          if (value.count > 15) return 'color-scale-3';
          if (value.count > 7) return 'color-scale-2';
          return 'color-scale-1';
        }}
        values={values}
      />
    </Content>
  );
};

import MUICard from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Alert, { Color as AlertColor } from '@material-ui/lab/Alert';
import React from 'react';
import ReactGA from 'react-ga';
import SplitPane from 'react-split-pane';
import useMeasure from 'react-use-measure';
import styled from 'styled-components';

import { usePaperIdentifier } from '../../hooks/params';
import { usePaperQuery } from '../../hooks/query';
import { CommentForm } from '../CommentForm';
import { PaperCommentCard } from '../PaperCommentCard';

const Content = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 100%;

  & .Pane.horizontal.Pane2 {
    overflow-y: auto;
  }
`;

const CommentsDiv = styled.div`
  position: relative;
  width: 100%;
  word-break: break-all;
  height: 100%;
  overflow-y: scroll;
`;

const Centering = styled.div`
  width: 100%;
  margin: auto 0;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 36px;
  text-align: center;
`;

const Card = styled(MUICard)`
  margin: 0.5rem;
`;

const CommentFormWrap = styled.div`
  height: 100%;
  padding: 0.5rem;
`;

const NoComments = styled.div`
  text-align: center;
  color: #545454;
  margin-top: 20px;
`;

type SnackbarProps = {
  open: boolean;
  message: string;
  severity?: AlertColor;
};

export const Comments: React.FC = () => {
  const [ref, { height }] = useMeasure();
  const { source, originalID, version } = usePaperIdentifier();
  const commentsRef = React.useRef<HTMLDivElement>(null);
  const [snackbar, setSnackbar] = React.useState<SnackbarProps>({ open: false, message: '' });

  const { loading, data } = usePaperQuery({
    variables: {
      paperInput: {
        source,
        originalID,
        version,
      },
    },
  });
  const paperInfo = data?.paper;

  const comments = React.useMemo(
    () =>
      [...(paperInfo?.comments ?? [])].sort((c1, c2) => (c1.createdAt < c2.createdAt ? -1 : 1)) ??
      [],
    [paperInfo?.comments]
  );

  const closeSnackbar = React.useCallback(
    (): void => setSnackbar({ open: false, message: '' }),
    []
  );

  React.useEffect(() => {
    if (commentsRef.current) {
      commentsRef.current.scrollTop = commentsRef.current.scrollHeight;
    }
  }, [comments, commentsRef]);

  return (
    <Content ref={ref}>
      <SplitPane split="horizontal" maxSize={height - 150} defaultSize={height - 300}>
        <CommentsDiv ref={commentsRef}>
          {comments.length === 0 && (
            <Centering>
              {loading && <CircularProgress />}
              {!loading && <NoComments>There are no comments.</NoComments>}
            </Centering>
          )}
          {comments.map((comment) => (
            <Card key={comment.id} variant="outlined">
              <PaperCommentCard
                noPaperTitle
                openLinkNewTab
                paperComment={comment}
                truncatedLimit={1000}
              />
            </Card>
          ))}
        </CommentsDiv>
        <CommentFormWrap>
          <CommentForm
            paperInput={{ source, originalID, version }}
            onSuccess={(): void => {
              setSnackbar({
                open: true,
                message: 'Successfully posted your comment!',
                severity: 'success',
              });
              ReactGA.event({
                category: 'User',
                action: 'Post a paper comment',
                label: 'Extension',
              });
            }}
            onError={(): void => {
              setSnackbar({
                open: true,
                message: 'Failed to post your comment.',
                severity: 'error',
              });
            }}
          />
        </CommentFormWrap>
      </SplitPane>
      <Snackbar
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbar.open}
        onClick={closeSnackbar}
        onClose={closeSnackbar}
      >
        <Alert severity={snackbar.severity ?? 'info'}>{snackbar.message}</Alert>
      </Snackbar>
    </Content>
  );
};

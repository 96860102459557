import React from 'react';
import styled from 'styled-components';

import * as Util from '../util';

import { AnchorLink } from './Anchor';
import { Linkify } from './Linkify';
import { Markdown } from './Markdown';

const ExpandButton = styled(AnchorLink)`
  display: inline-block;
  margin-left: 5px;
  font-size: 0.8rem;
  cursor: pointer;
`;

const Pre = styled.pre`
  overflow: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const PreOrDiv: React.FC<{ pre: boolean }> = ({ children, pre }) => {
  if (pre) return <Pre>{children}</Pre>;
  return <div>{children}</div>;
};

export const TextExpander: React.FC<{
  text: string;
  truncatedLimit?: number;
  pre?: boolean;
  markdown?: boolean;
}> = ({ text, truncatedLimit = 200, pre = false, markdown = false }) => {
  const isRequiredToTruncate = truncatedLimit < text.length;
  const [isTruncated, setIsTruncated] = React.useState(isRequiredToTruncate);
  const truncatedText = isTruncated ? Util.truncate(text, truncatedLimit) : text;

  return (
    <div>
      <PreOrDiv pre={pre}>
        {markdown ? (
          <Markdown text={truncatedText} />
        ) : (
          <Linkify target="_blank">{truncatedText}</Linkify>
        )}
        {isRequiredToTruncate &&
          (isTruncated ? (
            <ExpandButton
              to=""
              offVisited
              onClick={(event): void => {
                event.preventDefault();
                setIsTruncated(false);
              }}
            >
              Show all
            </ExpandButton>
          ) : (
            <ExpandButton
              to=""
              offVisited
              onClick={(event): void => {
                event.preventDefault();
                setIsTruncated(true);
              }}
            >
              Show less
            </ExpandButton>
          ))}
      </PreOrDiv>
    </div>
  );
};

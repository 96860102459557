import { Button } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import React from 'react';
import styled from 'styled-components';

import { usePaperIdentifier } from '../../hooks/params';
import { usePaperQuery, PaperUserStatusName } from '../../hooks/query';
import { toPapers } from '../../routing';
import * as Util from '../../util';
import { FavoriteButton } from '../FavoriteButton';
import { PaperStatusButton } from '../PaperStatusButton';

import { NavigationMenu } from './NavigationMenu';
import { PdfLinkButton } from './PdfLinkButton';

const AppNavBar = styled(AppBar)`
  position: relative;
`;

const TopToolbar = styled(Toolbar)`
  height: 49px;
  padding: 0 5px;
  justify-content: flex-end;
`;

const Buttons = styled.div`
  display: flex;
  flex: 1;
  font-size: 22px;
`;

const FavoriteButtonWrap = styled.div`
  margin-left: 6px;
`;

const PaperStatusButtonWrap = styled.div``;

const ExternalIcon = styled(OpenInNewIcon)`
  font-size: 0.8rem;
`;

export const Navigation: React.FC = () => {
  const { source, originalID, version } = usePaperIdentifier();

  const { data, loading } = usePaperQuery({
    // TODO: optimize query
    variables: {
      paperInput: {
        source,
        originalID,
        version,
      },
    },
  });
  const paperInfo = data?.paper;

  return (
    <AppNavBar>
      <TopToolbar variant="dense">
        {!loading && (
          <Buttons>
            <PaperStatusButtonWrap>
              <PaperStatusButton
                color="inherit"
                paperInput={{
                  source,
                  originalID,
                  version,
                }}
                status={paperInfo?.status ?? PaperUserStatusName.PaperUserStatusUnRead}
                isExtension
              />
            </PaperStatusButtonWrap>
            <FavoriteButtonWrap>
              <FavoriteButton
                isExtension
                paperInput={{
                  source,
                  originalID,
                  version,
                }}
                isFavorite={paperInfo?.isFavorite ?? false}
              />
            </FavoriteButtonWrap>
            <Button
              size="small"
              color="inherit"
              onClick={(): void =>
                Util.openPortal(source && originalID ? toPapers(source, originalID, version) : '')
              }
            >
              portal
              <ExternalIcon fontSize="small" />
            </Button>
            <PdfLinkButton />
          </Buttons>
        )}
        <NavigationMenu />
      </TopToolbar>
    </AppNavBar>
  );
};

/**
 * Reducer
 */
import { Action } from 'redux';

export interface State {
  message: string;
}

const initialState: State = {
  message: 'Hello World!',
};

export const reducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    default:
      return state;
  }
};

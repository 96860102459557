import React from 'react';
import ReactLinkify from 'react-linkify';

export const Linkify: React.FC<{ target?: string }> = ({ target = '', children }) => (
  <ReactLinkify
    componentDecorator={(href, text, key): React.ReactElement => (
      <a href={href} key={key} target={target} rel="noopener noreferrer">
        {text}
      </a>
    )}
  >
    {children}
  </ReactLinkify>
);

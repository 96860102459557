import React from 'react';

import * as API from '../api';
import { UserContext } from '../context';

let gapi: any = null; // eslint-disable-line @typescript-eslint/no-explicit-any

export type AuthStatus =
  | 'unloaded'
  | 'loading'
  | 'unauthorized'
  | 'authorized'
  | 'authorizing'
  | 'error';

// TODO: Resolve "Warning: Can't perform a React state update on an unmounted component."
export const useAuth = (): {
  token: string | null;
  status: AuthStatus;
  signIn: () => void;
  signOut: () => void;
} => {
  const [token, setToken] = React.useState<string | null>(null);
  const [status, setStatus] = React.useState<AuthStatus>('unloaded');
  const [isSignedIn, setIsSignedIn] = React.useState(false);
  const { signIn: userSignIn, signOut: userSignOut } = React.useContext(UserContext);

  const googleSignIn = React.useCallback(() => {
    try {
      gapi.auth2.getAuthInstance().isSignedIn.listen(() => {
        const accessToken = gapi?.client?.getToken()?.access_token;
        if (accessToken) setToken(accessToken);
      });

      setStatus('authorizing');
      const accessToken = gapi?.client?.getToken()?.access_token;
      if (accessToken) {
        setToken(accessToken);
      } else {
        gapi.auth2.getAuthInstance().signIn();
      }
    } catch (e) {
      console.error(e);
      setStatus('error');
    }
  }, []);

  const googleSignOut = React.useCallback(() => {
    gapi.auth2.getAuthInstance().signOut();
    userSignOut();
  }, [userSignOut]);

  React.useEffect(() => {
    let mounted = true;

    const onLoad = (): void => {
      if (mounted) setStatus('loading');

      gapi = window.gapi;

      gapi.load('client:auth2', async () => {
        await gapi.client.init({
          clientId: '791649462140-msgksfv4g3ulepu3m0ea9o4d6najefok.apps.googleusercontent.com',
          scope: 'email',
        });

        if (mounted) setStatus('unauthorized');
        if (mounted) setIsSignedIn(gapi.auth2.getAuthInstance().isSignedIn.get());
      });
    };

    if (gapi) {
      if (mounted) setStatus('unauthorized');
    } else {
      const script = document.createElement('script');
      script.src = 'https://apis.google.com/js/api.js';
      script.async = true;
      script.onload = onLoad;
      script.onerror = (): void => console.info('on error');
      document.body.appendChild(script);
    }

    return (): void => {
      mounted = false;
    };
  }, []);

  React.useEffect(() => {
    let mounted = true;
    if (isSignedIn && mounted) {
      googleSignIn();
    }

    return (): void => {
      mounted = false;
    };
  }, [isSignedIn, googleSignIn]);

  React.useEffect(() => {
    let mounted = true;
    const signIn = async (): Promise<void> => {
      if (token) {
        const response = await API.signIn(token);
        if (!response) return;
        userSignIn(response?.email ?? '', response.userId);
        if (mounted) setStatus('authorized');
      }
    };
    void signIn();

    return (): void => {
      mounted = false;
    };
  }, [token, userSignIn]);

  return {
    token,
    status,
    signIn: googleSignIn,
    signOut: googleSignOut,
  };
};

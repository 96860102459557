import { Paper_Source_Type } from '../hooks/query';

// eslint-disable-next-line no-shadow
export enum StorageKeys {
  GoogleAccountId = 'googleAccountId',
  EditingComment = 'editingComment',
  Config = 'config',
}

const getStorage = (key: string): string => localStorage.getItem(key) ?? '';

const setStorage = (key: string, value: string): void => localStorage.setItem(key, value);

export const Storage = {
  get: (key: StorageKeys): string => {
    return getStorage(key);
  },
  set: (key: StorageKeys, value: string): void => {
    return setStorage(key, value);
  },
  getObject: <T = unknown>(key: StorageKeys): T => {
    return JSON.parse(getStorage(key));
  },
  setObject: <T = unknown>(key: StorageKeys, value: T): void => {
    return setStorage(key, JSON.stringify(value));
  },
  getByPaper: (key: StorageKeys, source: Paper_Source_Type, originalID: OriginalID): string => {
    return getStorage(`${key}_${source}_${originalID}`);
  },
  setByPaper: (
    key: StorageKeys,
    source: Paper_Source_Type,
    originalID: OriginalID,
    value: string
  ): void => {
    return setStorage(`${key}_${source}_${originalID}`, value);
  },
};

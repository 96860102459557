import { Paper_Source_Type } from './hooks/query';
import { paperSourceTypeToSource } from './util';

export const toPapers = (
  source: Paper_Source_Type,
  originalID: OriginalID,
  version: string | null
): string =>
  `/papers/${paperSourceTypeToSource(source)}/${encodeURIComponent(originalID)}${
    version ? `/${version}` : ''
  }`;

import React from 'react';
import styled from 'styled-components';

import { AccessedPaperCards } from './AccessedPaperCards';

const Content = styled.div`
  max-width: 800px;
  margin: 0px auto;
  padding-top: 18px;
`;

export const AccessLogs: React.FC = () => {
  return (
    <Content>
      <AccessedPaperCards offActions perPage={100} />
    </Content>
  );
};

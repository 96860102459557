import { CircularProgress } from '@material-ui/core';
import React from 'react';
import ReactGA from 'react-ga';
import styled, { createGlobalStyle } from 'styled-components';

import { RoutingContext, UserContext, PaperIdentifierForExtensionContext } from '../../context';
import {
  useOriginalIDFromParams,
  useSourceFromParams,
  useVersionFromParams,
} from '../../hooks/params';
import {
  Paper_Source_Type,
  useCreatePaperAccessLogMutation,
  useFindPaperFromSemanticScholarIdLazyQuery,
} from '../../hooks/query';
import { PaperIdentifier } from '../../types/Paper';
import { Route } from '../../types/Route';

import { Comments } from './Comments';
import { Favorites } from './Favorites';
import { Navigation } from './Navigation';
import { ReadLaters } from './ReadLaters';
import { Readers } from './Readers';
import { Sidebar } from './Sidebar';
import { Tabs } from './Tabs';
import { Tags } from './Tags';

const GlobalStyle = createGlobalStyle`
 .MuiDialog-paper {
    margin: 5px;
  }
`;

const AppContent = styled.div`
  height: calc(100vh - 50px - 40px);
  border-left: 1px solid #dcdcdc;
`;

const TabContent = styled.div`
  position: relative;
  height: calc(100% - 53px);
`;

const ProgressWrap = styled.div`
  position: absolute;
  top: calc(50% - 24px);
  left: calc(50% - 24px);
`;

export const App: React.FC = () => {
  const { session } = React.useContext(UserContext);
  const [route, setRoute] = React.useState(Route.Comments);
  const [paperIdentifier, setPaperIdentifier] = React.useState<PaperIdentifier>();
  const [createPaperAccessLog] = useCreatePaperAccessLogMutation();
  const [findPaperFromSemanticScholarId, findPaperFromSemanticScholarIdData] =
    useFindPaperFromSemanticScholarIdLazyQuery();
  const source = useSourceFromParams();
  const originalID = useOriginalIDFromParams();
  const version = useVersionFromParams();

  React.useEffect(() => {
    if (paperIdentifier) {
      try {
        void createPaperAccessLog({
          variables: {
            paperInput: paperIdentifier,
          },
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [createPaperAccessLog, paperIdentifier]);

  React.useEffect(() => {
    ReactGA.set({ userId: session.email ?? 'unknown' });
    ReactGA.pageview(`/extension/${source}/${originalID}/${version}`);
  }, [source, originalID, version, session.email]);

  React.useEffect(() => {
    // Find paper identifier data by semantic scholar id
    if (source === Paper_Source_Type.SemanticScholar) {
      void findPaperFromSemanticScholarId({ variables: { id: originalID } });
    }
  }, [findPaperFromSemanticScholarId, originalID, source]);

  React.useEffect(() => {
    // Set paper identifier
    if (source === Paper_Source_Type.SemanticScholar) {
      if (findPaperFromSemanticScholarIdData.data?.findPaperFromSemanticScholarID) {
        const id = findPaperFromSemanticScholarIdData.data?.findPaperFromSemanticScholarID;
        setPaperIdentifier({ source: id.source, originalID: id.originalID, version: id.version });
      }
    } else if (source != null) {
      setPaperIdentifier({ source, originalID, version: version ?? '' });
    }
  }, [
    findPaperFromSemanticScholarId,
    findPaperFromSemanticScholarIdData.data?.findPaperFromSemanticScholarID,
    originalID,
    source,
    version,
  ]);

  return (
    <RoutingContext.Provider value={route}>
      <GlobalStyle />
      <Sidebar>
        {paperIdentifier && (
          <PaperIdentifierForExtensionContext.Provider value={paperIdentifier}>
            <Navigation />
            <Tags />
            <AppContent>
              <Tabs setRoute={setRoute} />
              <TabContent>
                {route === Route.Comments && <Comments />}
                {route === Route.Favorites && <Favorites />}
                {route === Route.Readers && <Readers />}
                {route === Route.ReadLaters && <ReadLaters />}
              </TabContent>
            </AppContent>
          </PaperIdentifierForExtensionContext.Provider>
        )}
        {!paperIdentifier && findPaperFromSemanticScholarIdData.loading && (
          <ProgressWrap>
            <CircularProgress />
          </ProgressWrap>
        )}
      </Sidebar>
    </RoutingContext.Provider>
  );
};

import { CardActionArea, Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import HelpIcon from '@material-ui/icons/Help';
import LabelIcon from '@material-ui/icons/Label';
import LabelOffIcon from '@material-ui/icons/LabelOff';
import React from 'react';
import styled from 'styled-components';

import { Tag, useTagPapersQuery, TagEventType, TagPapersQuery } from '../hooks/query';
import { toPapers } from '../routing';
import * as Util from '../util';

import { Anchor } from './Anchor';
import { Cards } from './Cards';
import { NumberBadge } from './NumberBadge';
import { UserIcon } from './UserIcon';

const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto 20px;
`;

const Header = styled.div`
  display: flex;
`;

const HeaderTitle = styled.span``;

const CardInner = styled.div`
  padding: 1rem;
`;

const CreatedAt = styled(Typography)`
  margin: 5px 0 0;
  font-size: 0.7rem;
`;

const EventType = styled(Typography)`
  display: flex;
  align-items: flex-start;
  font-size: 0.9rem;
  font-weight: bold;

  & > svg {
    margin-right: 5px;
  }
`;

const PaperTitle = styled.div`
  font-size: 15px;
`;

const Footer = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
`;

export const TagEventCards: React.FC<{
  tagName: Tag['name'];
  perPage?: number;
}> = ({ tagName, perPage = 3 }) => {
  const [loadedEvents, setLoadedEvents] = React.useState<TagPapersQuery['tag']['events']['edge']>(
    []
  );
  const [cursor, setCursor] = React.useState<string | undefined>();
  const { loading, data } = useTagPapersQuery({
    variables: {
      name: tagName,
      pagingInput: {
        first: 1,
      },
      eventsPagingInput: {
        first: perPage,
        after: cursor,
      },
    },
  });
  const events = data?.tag.events;
  const nextCursor = events?.pageInfo.endCursor;
  const total = events?.total ?? 0;
  const hasNext = events?.pageInfo.hasNextPage;
  const hasData = (events?.edge ?? []).length > 0;

  const load = React.useCallback(() => {
    if (nextCursor) setCursor(nextCursor);
  }, [nextCursor]);

  React.useEffect(() => {
    const newEvents = [...loadedEvents, ...(events?.edge ?? [])];
    setLoadedEvents(newEvents);
  }, [events]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Content>
      <Cards
        header={
          <Header>
            <HeaderTitle>History</HeaderTitle>
            <NumberBadge>{total}</NumberBadge>
          </Header>
        }
        loading={loading && !hasData}
        hasData={hasData}
        noDataText="No events"
        loadingSkeletonSize={perPage}
        load={load}
        last={!hasNext}
      >
        {loadedEvents.map((event) => (
          <div key={event.occurredAt}>
            <CardActionArea>
              <CardInner>
                {event.event === TagEventType.TagEventCreated && (
                  <EventType color="secondary">
                    <LabelIcon color="secondary" fontSize="small" />
                    ADD
                  </EventType>
                )}
                {event.event === TagEventType.TagEventDeleted && (
                  <EventType color="error">
                    <LabelOffIcon color="error" fontSize="small" />
                    REMOVE
                  </EventType>
                )}
                {event.event === TagEventType.TagEventUnknown && (
                  <EventType>
                    <HelpIcon color="disabled" fontSize="small" />
                    UNKNOWN
                  </EventType>
                )}
                <PaperTitle>
                  <Anchor
                    to={toPapers(
                      event.paper.paper.source,
                      event.paper.paper.originalID,
                      event.paper.paper.version
                    )}
                  >
                    {event.paper.paper.title}
                  </Anchor>
                </PaperTitle>
                <Footer>
                  <UserIcon user={event.user} />
                  <CreatedAt color="textSecondary">
                    {Util.toLocaleString(event.occurredAt)}
                  </CreatedAt>
                </Footer>
              </CardInner>
            </CardActionArea>
            <Divider />
          </div>
        ))}
      </Cards>
    </Content>
  );
};

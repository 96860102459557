import { Card, Grid, Input, InputLabel } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

import { TagsCard } from './TagsCard';

const Content = styled.div`
  max-width: 1200px;
  margin: 0px auto;
  padding-top: 18px;
`;

const FilteringCard = styled(Card)`
  padding: 10px;
`;

const FilteringInput = styled(Input)`
  width: 100%;
`;

export const Tags: React.FC = () => {
  const [prefix, setPrefix] = React.useState('');
  return (
    <Content>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <FilteringCard variant="outlined">
            <InputLabel htmlFor="filter-form">Filtering (prefix match)</InputLabel>
            <FilteringInput
              id="filter-form"
              value={prefix}
              onChange={(event): void => setPrefix(event.target.value)}
            />
          </FilteringCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <TagsCard mode="user" prefix={prefix} perPage={100000} />
        </Grid>
        <Grid item xs={12} md={4}>
          <TagsCard mode="team" prefix={prefix} perPage={100000} />
        </Grid>
        <Grid item xs={12} md={4}>
          <TagsCard mode="shared" prefix={prefix} perPage={100000} />
        </Grid>
      </Grid>
    </Content>
  );
};

import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import styled from 'styled-components';

import { useAuth } from '../hooks/auth';

import { SignInButton } from './SignInButton';
import { OnlySignedIn } from './extension/OnlySignedIn';
import { OnlySignedOut } from './extension/OnlySignedOut';

const SignInButtonWrap = styled.div`
  position: absolute;
  top: calc(50% - 24px);
  left: calc(50% - 24px);
`;

const ProgressWrap = styled.div`
  position: absolute;
  top: calc(50% - 24px);
  left: calc(50% - 24px);
`;

export const GoogleAuth: React.FC = ({ children }) => {
  const { status } = useAuth();
  const loaded = status === 'unauthorized' || status === 'authorized' || status === 'error';

  return (
    <div>
      {loaded ? (
        <>
          <OnlySignedOut>
            <SignInButtonWrap>
              <SignInButton />
            </SignInButtonWrap>
          </OnlySignedOut>
          <OnlySignedIn>{children}</OnlySignedIn>
        </>
      ) : (
        <ProgressWrap>
          <CircularProgress />
        </ProgressWrap>
      )}
    </div>
  );
};

import { createContext } from 'react';

import { Paper_Source_Type } from './hooks/query';
import { PaperIdentifier } from './types/Paper';
import { Route } from './types/Route';
import { Session, SessionUserId } from './types/User';

const session: Session = {
  userId: -1,
  email: '',
};

// eslint-disable-next-line import/no-mutable-exports
export let userContext: {
  session: Session;
  signIn: (email: string, userId: SessionUserId) => void;
  signOut: () => void;
  isSignedIn: () => boolean;
} = {
  session,
  signIn: () => {},
  signOut: () => {},
  isSignedIn: () => false,
};

export const forceUpdateList: (() => void)[] = [];

userContext.signIn = (email: string, userId: SessionUserId): void => {
  userContext = { ...userContext, session: { ...session, email, userId } };
  forceUpdateList.forEach((update) => update());
};

userContext.signOut = (): void => {
  userContext = { ...userContext, session: { ...session, email: '' } };
  forceUpdateList.forEach((update) => update());
};

userContext.isSignedIn = (): boolean =>
  typeof userContext.session.email === 'string' &&
  userContext.session.email.includes('@preferred.jp');

export const UserContext = createContext(userContext);

export const RoutingContext = createContext(Route.Comments);

export const PaperIdentifierForExtensionContext = createContext<PaperIdentifier>({
  source: Paper_Source_Type.ArxivOrg,
  originalID: '',
  version: '',
});

import {
  Button,
  CardActionArea,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import React from 'react';
import ReactGA from 'react-ga';
import styled from 'styled-components';

import {
  Tag,
  TagToSlack,
  useDeleteTagToSlackMutation,
  useTagPapersQuery,
  useUpdateTagToSlackMutation,
} from '../hooks/query';

import { Cards } from './Cards';
import { NumberBadge } from './NumberBadge';

const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeaderTitle = styled.span``;

const CardInner = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  letter-spacing: 1px;
  font-size: 1rem;
  height: 52px;
  color: ${({ theme }): string => theme.palette.grey[700]};

  & .clearIcon {
    display: none;
  }

  &:hover .clearIcon {
    display: block;
  }
`;

const Hash = styled.span`
  margin-right: 3px;
`;

const HeaderButtonWrap = styled.div`
  padding-top: 3px;
  height: 28px;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }): string => theme.palette.primary.main};
  }
`;

const AddButton = styled(AddIcon)``;

const DeleteButton = styled(DeleteOutlineIcon)`
  cursor: pointer;
  color: ${({ theme }): string => theme.palette.error.main};

  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }): string => theme.palette.error.dark};
  }
`;

const ChannelName = styled.div`
  margin-top: 10px;
  background-color: ${({ theme }): string => theme.palette.grey[100]};
  color: ${({ theme }): string => theme.palette.grey[700]};
  padding: 8px 19px;
  text-align: center;
  font-size: 1rem;
`;

const ChannelCard: React.FC<{
  tagName: Tag['name'];
  channelName: TagToSlack['channelName'];
}> = ({ tagName, channelName }) => {
  const [deleteTagToSlack] = useDeleteTagToSlackMutation({ refetchQueries: ['TagPapers'] });

  const [openDeleter, setOpenDeleter] = React.useState(false);
  const closeDeleter = React.useCallback(() => setOpenDeleter(false), []);

  const deleteSlackChannel = React.useCallback(() => {
    void deleteTagToSlack({
      variables: {
        name: tagName,
        channel: channelName,
      },
    });
    setOpenDeleter(false);
  }, [channelName, deleteTagToSlack, tagName]);

  return (
    <div key={channelName}>
      <CardActionArea>
        <CardInner>
          <div>
            <Hash>{channelName[0]}</Hash>
            {channelName.slice(1)}
          </div>
          <DeleteButton
            className="clearIcon"
            fontSize="small"
            onClick={(): void => setOpenDeleter(true)}
          />
        </CardInner>
      </CardActionArea>
      <Divider />
      <Dialog open={openDeleter} onClose={closeDeleter} aria-labelledby="delete-tag-dialog-title">
        <DialogTitle id="add-tag-dialog-title">Unsubscribe to the tag</DialogTitle>
        <DialogContent>
          Delete this Slack channel from the tag subscription
          <ChannelName>
            <Hash>{channelName[0]}</Hash>
            {channelName.slice(1)}
          </ChannelName>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleter} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteSlackChannel} color="primary">
            Unsubscribe
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const TagSlackChannelCards: React.FC<{
  tagName: Tag['name'];
  perPage?: number;
}> = ({ tagName, perPage = 100 }) => {
  const [updateTagToSlack] = useUpdateTagToSlackMutation({ refetchQueries: ['TagPapers'] });
  const [openAdder, setOpenAdder] = React.useState(false);
  const [channelName, setChannelName] = React.useState('');

  const { loading, data } = useTagPapersQuery({
    variables: {
      name: tagName,
      pagingInput: { first: 1 },
      eventsPagingInput: { first: 1 },
    },
  });
  const channels = data?.tag.channels ?? [];
  const hasData = channels.length > 0;

  const closeAdder = React.useCallback(() => setOpenAdder(false), []);

  const addSlackChannel = React.useCallback(() => {
    (async () => {
      const firstLetter = channelName[0];
      await updateTagToSlack({
        variables: {
          name: tagName,
          channel: firstLetter === '#' ? channelName : `#${channelName}`,
        },
      });
      setOpenAdder(false);
      ReactGA.event({
        category: 'User',
        action: 'Add slack channel to tag',
        label: 'Portal',
      });
    })();
  }, [channelName, tagName, updateTagToSlack]);

  return (
    <Content>
      <Cards
        header={
          <Header>
            <div>
              <HeaderTitle>Slack channels</HeaderTitle>
              <NumberBadge>{channels.length}</NumberBadge>
            </div>
            <HeaderButtonWrap>
              <AddButton onClick={(): void => setOpenAdder(true)} />
            </HeaderButtonWrap>
          </Header>
        }
        loading={loading && !hasData}
        hasData={hasData}
        noDataText="No channels"
        loadingSkeletonSize={perPage}
      >
        {channels.map((channel) => (
          <ChannelCard
            key={channel.channelName}
            tagName={tagName}
            channelName={channel.channelName}
          />
        ))}
      </Cards>
      <Dialog open={openAdder} onClose={closeAdder} aria-labelledby="add-tag-dialog-title">
        <DialogTitle id="add-tag-dialog-title">Subscribe to the tag on Slack Channel </DialogTitle>
        <DialogContent>
          You can subscribe to notifications about papers tied to this tag on a Slack channel.
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Slack channel name"
            fullWidth
            placeholder="#channel-name"
            onChange={(event): void => setChannelName(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeAdder} color="primary">
            Cancel
          </Button>
          <Button onClick={addSlackChannel} color="primary" disabled={!channelName}>
            Subscribe
          </Button>
        </DialogActions>
      </Dialog>
    </Content>
  );
};

import React from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';

import { App } from './App';
import { App as ExtensionSidebar } from './extension';

export const Router: React.FC = () => {
  const history = useHistory();
  let redirect = false; // workaround

  if (window.location.hash.startsWith('#/')) {
    const path = window.location.hash.slice(1);
    redirect = true;
    history.push(path);
  }

  return (
    <Switch>
      <Redirect
        exact
        from="/extension/:originalID/:meta"
        to="/extension/arxiv/:originalID/noVersion"
      />
      <Route exact path="/extension/:source/:originalID/:version" component={ExtensionSidebar} />
      <Route
        path="/"
        component={(): React.ReactElement => <App redirectToDashboard={!redirect} />}
      />
    </Switch>
  );
};

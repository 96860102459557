import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { UserContext } from '../context';
import { useMyAccessLogsQuery } from '../hooks/query';
import { numberToId } from '../util';

import { AccessedPaperCards } from './AccessedPaperCards';
import { ChromeExtensionGuidanceCard } from './ChromeExtensionGuidanceCard';
import { FilteredPaperCards } from './FilteredPaperCards';
import { NotificationCards } from './NotificationCards';
import { PaperCommentCards } from './PaperCommentCards';
import { ProfileCard } from './ProfileCard';
import { RankingPaperCards } from './RankingPaperCards';
import { TagsCard } from './TagsCard';

const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 18px;
`;

const GuidanceGrid = styled(Grid)`
  padding-bottom: 8px;
`;

export const DashBoard: React.FC = () => {
  const { session } = React.useContext(UserContext);
  const { userId: userIdRaw } = useParams<{ userId: string }>();
  const userId = parseInt(userIdRaw, 10);
  const userStringId = numberToId('User', userId);
  const isMe = userId === session.userId;

  const { loading, data } = useMyAccessLogsQuery({
    variables: {
      pagingInput: {
        first: 1,
      },
    },
  });

  return (
    <Content>
      {!loading && data?.me.accessLogs.total === 0 && (
        <GuidanceGrid container spacing={2}>
          <Grid item xs={12}>
            <ChromeExtensionGuidanceCard />
          </Grid>
        </GuidanceGrid>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <ProfileCard userId={userStringId} />
          {isMe && <TagsCard mode="user" perPage={20} />}
          {isMe && <NotificationCards perPage={3} />}
          {isMe && <AccessedPaperCards perPage={5} />}
          {isMe && <PaperCommentCards user="all" perPage={3} />}
          <PaperCommentCards user={userStringId} perPage={isMe ? 3 : 10} />
        </Grid>
        <Grid item xs={12} md={8}>
          {isMe && <RankingPaperCards perPage={5} />}
          <FilteredPaperCards user={userStringId} perPage={5} />
        </Grid>
      </Grid>
    </Content>
  );
};

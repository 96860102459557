import {
  Dialog,
  DialogTitle as MUIDialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Tabs,
  Tab,
} from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import GroupIcon from '@material-ui/icons/Group';
import SecurityIcon from '@material-ui/icons/Security';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import React from 'react';
import ReactGA from 'react-ga';
import styled from 'styled-components';

import { UserContext } from '../context';
import { usePrevious } from '../hooks';
import {
  useCreatePaperTagMutation,
  PaperInput,
  useMyTagsQuery,
  PaperTag,
  useTagNameByPrefixQuery,
} from '../hooks/query';
import { isSharedTag, isTeamTag, normalizeTagName } from '../util';

import { Tag } from './Tag';

const Mode = ['user', 'team', 'shared'] as const;
type Mode = typeof Mode[number];

const tabIndex = {
  user: 0,
  team: 1,
  shared: 2,
};

const AddTagIcon = styled(AddBoxIcon)`
  cursor: pointer;
`;

const DialogTitle = styled(MUIDialogTitle)`
  & > h2 {
    display: flex;
    align-items: center;
  }

  & svg {
    margin-right: 5px;
  }
`;

const Tags = styled.div`
  margin-top: 10px;
  padding: 5px 0;

  & > div {
    margin: 0 10px 10px 0;
  }
`;

export const TagAdder: React.FC<{
  mode?: Mode;
  paperInput: PaperInput;
  existingTags?: Omit<PaperTag, 'paper'>[];
  isExtension?: boolean;
}> = ({ mode: givenMode = 'shared', paperInput, existingTags, isExtension = false }) => {
  const {
    session: { userId },
  } = React.useContext(UserContext);
  const [mode, setMode] = React.useState(givenMode);
  const [open, setOpen] = React.useState(false);
  const [tagName, setTagName] = React.useState('');
  const [checkedTagNames, setCheckedTagNames] = React.useState<string[]>([]);
  const [createPaperTag] = useCreatePaperTagMutation({ refetchQueries: ['Paper'] });
  const { data, refetch } = useMyTagsQuery({
    variables: {
      pagingInput: { first: 20 },
    },
  });
  const userTags = data?.me.tags.edge ?? [];
  const allTags = useTagNameByPrefixQuery({
    variables: {
      prefix: '',
    },
  });
  const sharedTags = (allTags.data?.tagNamesByPrefix ?? []).filter((name) => isSharedTag(name));
  const teamTags = (allTags.data?.tagNamesByPrefix ?? []).filter((name) => isTeamTag(name));
  const suggestedTags = useTagNameByPrefixQuery({
    variables: {
      prefix:
        mode === 'user'
          ? `user:${userId}:${tagName}`
          : mode === 'team'
          ? `team:${tagName}`
          : tagName,
    },
  });
  const existingTagNames = existingTags?.map((e) => e.name) ?? [];
  const exampleTagNames = (
    mode === 'user' ? userTags.map((t) => t.name) : mode === 'team' ? teamTags : sharedTags
  )
    .filter((name) => !existingTagNames.includes(name))
    .sort((a, b) => a.localeCompare(b))
    .slice(0, 20);

  const close = React.useCallback(() => {
    setOpen(false);
  }, []);

  const createTagHandler = React.useCallback(
    (givenTagName: string, isClose = true) => {
      (async () => {
        const newTagName = normalizeTagName(givenTagName);
        const name =
          mode === 'user'
            ? `user:${userId}:${newTagName}`
            : mode === 'team'
            ? `team:${newTagName}:default`
            : newTagName;
        await createPaperTag({ variables: { paperInput, name } });
        if (isClose) setOpen(false);
        setTagName('');
        ReactGA.event({
          category: 'User',
          action: 'Add tag to paper',
          label: isExtension ? 'Extension' : 'Portal',
        });
      })();
    },
    [mode, userId, createPaperTag, paperInput, isExtension]
  );

  const submit = React.useCallback(() => {
    if (tagName) {
      createTagHandler(tagName);
    }
    if (checkedTagNames.length > 0) {
      checkedTagNames.forEach((name) => {
        createTagHandler(name);
      });
    }
  }, [checkedTagNames, createTagHandler, tagName]);

  const prevOpen = usePrevious(open);
  React.useEffect(() => {
    if (!prevOpen && open) {
      (async () => {
        await refetch();
        await suggestedTags.refetch();
        setCheckedTagNames([]);
      })();
    }
  }, [prevOpen, open, refetch, suggestedTags]);

  return (
    <>
      <AddTagIcon color="action" onClick={(): void => setOpen(true)} />
      <Dialog open={open} onClose={close} aria-labelledby="add-tag-dialog-title">
        <Tabs
          variant="fullWidth"
          value={tabIndex[mode]}
          indicatorColor="primary"
          textColor="primary"
          onChange={(_: unknown, newValue: number): void => {
            setMode(Mode[newValue ?? 0]);
          }}
        >
          <Tab label="Your tag" />
          <Tab label="Team tag" />
          <Tab label="Shared tag" />
        </Tabs>
        <DialogTitle id="add-tag-dialog-title">
          {mode === 'user' ? (
            <>
              <SecurityIcon fontSize="small" /> Add your tag
            </>
          ) : mode === 'team' ? (
            <>
              <GroupIcon fontSize="small" /> Add a team tag
            </>
          ) : (
            'Add a shared tag'
          )}
        </DialogTitle>
        <DialogContent>
          {mode === 'user'
            ? 'These tags are not displayed to other users.'
            : mode === 'team'
            ? 'Team tags are used by teams to manage their papers.'
            : 'Share tags are displayed to all users'}
          <Autocomplete
            id="label-text-field"
            freeSolo
            options={(suggestedTags.data?.tagNamesByPrefix ?? []).map((t) => normalizeTagName(t))}
            style={{ width: 300 }}
            value={tagName}
            onChange={(_: unknown, value: string | null): void => {
              if (value != null) setTagName(value);
            }}
            inputValue={tagName}
            onInputChange={(_: unknown, value: string | null): void => {
              if (value != null) setTagName(value);
            }}
            renderInput={(params: unknown): JSX.Element => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                autoFocus
                margin="dense"
                id="name"
                label="New tag name"
                fullWidth
                // onKeyDown={(e): void => {
                //   // Press Enter key
                //   if (e.keyCode === 13) {
                //     createTagHandler();
                //   }
                // }}
              />
            )}
          />
          {exampleTagNames.length > 0 && (
            <Tags>
              {exampleTagNames.map((tName) => {
                const exist = Boolean(checkedTagNames?.find((t) => t === tName));
                return (
                  <Tag
                    key={tName}
                    tagName={tName}
                    color={exist ? 'secondary' : 'default'}
                    onClick={(name): void => {
                      const index = checkedTagNames.indexOf(name);
                      const checked = index >= 0;
                      setCheckedTagNames((prev) => {
                        if (checked) {
                          const newNames = [...prev];
                          newNames.splice(index, 1);
                          return newNames;
                        }
                        return [...prev, name];
                      });
                    }}
                  />
                );
              })}
            </Tags>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary">
            Cancel
          </Button>
          <Button
            onClick={submit}
            color="primary"
            disabled={!tagName && checkedTagNames.length === 0}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

import { useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { PaperIdentifierForExtensionContext } from '../context';
import { PaperIdentifier } from '../types/Paper';

import { Paper_Source_Type } from './query';

export const useSourceFromParams = (): Paper_Source_Type | null => {
  const { source } = useParams<{ source: Source }>();
  if (source === 'arxiv') return Paper_Source_Type.ArxivOrg;
  if (source === 'biorxiv') return Paper_Source_Type.Biorxiv;
  if (source === 'medrxiv') return Paper_Source_Type.Medrxiv;
  if (source === 'openreview') return Paper_Source_Type.Openreview;
  if (source === 'semanticScholar') return Paper_Source_Type.SemanticScholar;
  if (source === 'doi') return Paper_Source_Type.Doi;
  if (source === 'chemrxiv') return Paper_Source_Type.Chemrxiv;
  if (source === 'ieee') return Paper_Source_Type.IEEE;
  return null;
};

export const useOriginalIDFromParams = (): string => {
  const { originalID } = useParams<{ originalID: string }>();
  return originalID ? decodeURIComponent(originalID) : 'no original id';
};

export const useVersionFromParams = (): string | null => {
  const { version } = useParams<{ version: string }>();
  if (!version || version === 'noVersion') return null;
  return version;
};

export const usePaperIdentifier = (): PaperIdentifier => {
  const paperIdentifier = useContext(PaperIdentifierForExtensionContext);
  return paperIdentifier;
};

export const useQuery = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};

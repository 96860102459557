import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React from 'react';
import ReactGA from 'react-ga';
import styled from 'styled-components';

import { useUpdatePaperUserStatusMutation, PaperInput, PaperUserStatusName } from '../hooks/query';

const Content = styled.div`
  & .MuiButton-colorInherit.MuiButton-containedSizeSmall {
    background-color: white;
    color: ${({ theme }): string => theme.palette.primary.main};
  }
`;

export const PaperStatusButton: React.FC<{
  color?: 'primary' | 'secondary' | 'inherit' | 'default';
  paperInput: PaperInput;
  status: PaperUserStatusName;
  isExtension?: boolean;
  variant?: 'text' | 'contained' | 'outlined';
  onChange?: () => void;
}> = ({ color = 'primary', paperInput, status, isExtension = false, onChange }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const [updatePaperUserStatus] = useUpdatePaperUserStatusMutation({ refetchQueries: ['Paper'] });

  const handleToggle = React.useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const updateStatus = React.useCallback(
    (newStatus: PaperUserStatusName) => {
      (async (): Promise<void> => {
        if (newStatus === PaperUserStatusName.PaperUserStatusRead) {
          ReactGA.event({
            category: 'User',
            action: 'Mark paper as read',
            label: isExtension ? 'Extension' : 'Portal',
          });
        } else if (newStatus === PaperUserStatusName.PaperUserStatusReadLater) {
          ReactGA.event({
            category: 'User',
            action: 'Mark paper as read later',
            label: isExtension ? 'Extension' : 'Portal',
          });
        }
        await updatePaperUserStatus({
          variables: { paperInput, statusName: newStatus },
        });
        if (onChange) onChange();
      })();
    },
    [updatePaperUserStatus, paperInput, isExtension, onChange]
  );

  return (
    <Content>
      <ButtonGroup
        size="small"
        color={color}
        aria-label="outlined primary button group"
        variant="outlined"
        ref={anchorRef}
      >
        <Button disableElevation>
          {status === PaperUserStatusName.PaperUserStatusUnRead && 'Unread'}
          {status === PaperUserStatusName.PaperUserStatusRead && 'Read'}
          {status === PaperUserStatusName.PaperUserStatusReadLater && 'Read Later'}
        </Button>
        <Button size="small" onClick={handleToggle}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }): React.ReactNode => (
          <Grow
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={(): void => setOpen(false)}>
                <MenuList id="split-button-menu">
                  <MenuItem
                    selected={status === PaperUserStatusName.PaperUserStatusUnRead}
                    onClick={(): void => updateStatus(PaperUserStatusName.PaperUserStatusUnRead)}
                  >
                    Unread
                  </MenuItem>
                  <MenuItem
                    selected={status === PaperUserStatusName.PaperUserStatusRead}
                    onClick={(): void => updateStatus(PaperUserStatusName.PaperUserStatusRead)}
                  >
                    Read
                  </MenuItem>
                  <MenuItem
                    selected={status === PaperUserStatusName.PaperUserStatusReadLater}
                    onClick={(): void => updateStatus(PaperUserStatusName.PaperUserStatusReadLater)}
                  >
                    Read Later
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Content>
  );
};

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';

export const ConfirmationModal: React.FC<{
  open: boolean;
  title: string | React.ReactElement;
  message: string | React.ReactElement;
  onOk: () => void;
  onCancel: () => void;
}> = ({ open, title, message, onOk, onCancel }) => (
  <Dialog open={open} onClose={(): void => onCancel()}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>{message}</DialogContent>
    <DialogActions>
      <Button autoFocus onClick={(): void => onCancel()}>
        Cancel
      </Button>
      <Button onClick={(): void => onOk()} color="primary">
        Accept
      </Button>
    </DialogActions>
  </Dialog>
);

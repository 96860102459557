import { CardActions as MUICardActions, Divider } from '@material-ui/core';
import MUICard from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import React from 'react';
import styled from 'styled-components';

import { UserContext } from '../context';
import { useTagNameByPrefixQuery } from '../hooks/query';
import { isSharedTag, isTeamTag } from '../util';

import { FullBleedButton } from './FullBleedButton';
import { NumberBadge } from './NumberBadge';
import { Tag } from './Tag';

const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto 20px;
`;

const Card = styled(MUICard)``;

const CardsHeader = styled(Typography)`
  padding: 0.5rem 1rem;
  text-transform: capitalize;
`;

const Tags = styled.div`
  padding: 15px 10px;

  & > div {
    margin: 0 10px 10px 0;
  }
`;

const LoadButton = styled(FullBleedButton)`
  & span {
    display: flex;
    justify-content: center !important;
  }
`;

const CardActions = styled(MUICardActions)`
  padding: 0;
`;

export const TagsCard: React.FC<{
  mode: 'user' | 'shared' | 'team';
  prefix?: string;
  perPage: number;
}> = ({ mode, prefix = '', perPage = 10 }) => {
  const {
    session: { userId },
  } = React.useContext(UserContext);

  const filteredTags = useTagNameByPrefixQuery({
    variables: {
      prefix:
        mode === 'user' ? `user:${userId}:${prefix}` : mode === 'team' ? `team:${prefix}` : prefix,
    },
  });
  const sharedTags = (filteredTags.data?.tagNamesByPrefix ?? []).filter((tagName) =>
    isSharedTag(tagName)
  );
  const teamTags = (filteredTags.data?.tagNamesByPrefix ?? []).filter((tagName) =>
    isTeamTag(tagName)
  );
  const tagNames = [
    ...(mode === 'user'
      ? filteredTags.data?.tagNamesByPrefix ?? []
      : mode === 'team'
      ? teamTags
      : sharedTags),
  ].sort((a, b) => a.localeCompare(b));

  const [showAll, setShowAll] = React.useState(false);

  return (
    <Content>
      <Card variant="outlined">
        <CardsHeader variant="subtitle1" color="textSecondary">
          {mode === 'user' ? 'Your' : mode} tags
          <NumberBadge>{tagNames.length ?? 0}</NumberBadge>
        </CardsHeader>
        <Divider />
        <Tags>
          {[...tagNames].slice(0, showAll ? 10000 : perPage).map((tagName) => (
            <Tag key={tagName} tagName={tagName} />
          ))}
        </Tags>
        <Divider />
        {!showAll && tagNames.length > perPage && (
          <CardActions>
            <LoadButton color="primary" onClick={(): void => setShowAll(true)}>
              <KeyboardArrowDownIcon />
            </LoadButton>
          </CardActions>
        )}
      </Card>
    </Content>
  );
};

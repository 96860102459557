import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import React from 'react';
import styled from 'styled-components';

import { FullBleedButton } from './FullBleedButton';
import { NoDataCard } from './NoDataCard';
import { SkeletonCard } from './SkeletonCard';

const CardsHeader = styled(Typography)`
  padding: 0.5rem 1rem;
`;

const LoadButton = styled(FullBleedButton)`
  & span {
    display: flex;
    justify-content: center !important;
  }
`;

const Period = styled.div`
  display: flex;
  justify-content: center !important;
  color: ${({ theme }): string => theme.palette.grey[300]};

  & svg {
    width: 12px;
  }
`;

export const Cards: React.FC<{
  header: React.ReactElement | string;
  loading: boolean;
  hasData: boolean;
  noDataText?: string;
  loadingSkeletonSize?: number;
  load?: () => void;
  last?: boolean;
}> = ({
  children,
  header,
  loading,
  hasData,
  noDataText = 'No Data',
  loadingSkeletonSize = 1,
  load = null,
  last = false,
}) => {
  const showChildren = !loading && hasData;
  return (
    <Card variant="outlined">
      <CardsHeader variant="subtitle1" color="textSecondary">
        {header}
      </CardsHeader>
      <Divider />
      {loading &&
        [...Array(loadingSkeletonSize).keys()].map((k) => (
          <span key={k}>
            <SkeletonCard size={4} />
            {k + 1 < loadingSkeletonSize && <Divider />}
          </span>
        ))}
      {showChildren && children}
      {hasData ? (
        <>
          {!last && load && (
            <CardActions>
              <LoadButton color="primary" onClick={(): void => load()}>
                <KeyboardArrowDownIcon />
              </LoadButton>
            </CardActions>
          )}
          {last && (
            <Period>
              <FiberManualRecordIcon />
            </Period>
          )}
        </>
      ) : (
        <>{!loading && <NoDataCard text={noDataText} />}</>
      )}
    </Card>
  );
};

import { createMuiTheme } from '@material-ui/core';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#23419a',
    },
    secondary: {
      main: '#619a23',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});

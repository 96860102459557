import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckIcon from '@material-ui/icons/Check';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import styled from 'styled-components';

import { getArxivXML } from '../../api';
import { Paper_Source_Type, PaperInput } from '../../hooks/query';

const BibtexPreview = styled.div`
  min-height: 7.5rem;
  padding: 10px;
  background-color: ${({ theme }): string => theme.palette.grey[200]};
`;

const Pre = styled.pre`
  margin: 0;
  overflow-x: scroll;
`;

const CopyButton = styled(Button)`
  transition: ease 0.1s;
`;

export const BibtexDialog: React.FC<{
  paperInput: PaperInput;
  open: boolean;
  onClose: (event: React.MouseEvent) => void;
}> = ({ paperInput: { source, originalID }, open, onClose }) => {
  const [bibtex, setBibtex] = React.useState('');
  const [copied, setCopied] = React.useState(false);

  React.useEffect(() => {
    const getXMLForArxiv = async (): Promise<void> => {
      const xml = await getArxivXML({ arxivId: originalID });
      if (!xml) return;
      const authors = [...xml.querySelectorAll('author name')].map((name) => name.textContent);
      const published = xml.querySelector('entry published')?.textContent ?? '';

      const firstAuthorSplitted = (authors[0] ?? '').split(' ');
      const title = xml.querySelector('entry title')?.textContent ?? '';
      const author = authors.join(' and ');
      const year = new Date(published).getFullYear();
      const key = `${firstAuthorSplitted[
        firstAuthorSplitted.length - 1
      ].toLocaleLowerCase()}${year}${title
        .split(' ')[0]
        .toLowerCase()
        .replace(/[^0-9a-z]/gi, '')}`;
      setBibtex(
        `@article{${key},\n    title={${title}},\n    author={${author}},\n    year={${year}},\n    journal={arXiv preprint arXiv:${originalID}}\n}`
      );
    };
    if (open && !bibtex) {
      if (source === Paper_Source_Type.ArxivOrg) void getXMLForArxiv();
    }
  }, [source, originalID, open, bibtex]);

  React.useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 2000);
    }
  }, [copied]);

  return (
    <Dialog fullWidth onClose={onClose} aria-labelledby="bibtex-dialog" open={open}>
      <DialogTitle id="simple-dialog-title">Bibtex</DialogTitle>
      {source === Paper_Source_Type.ArxivOrg ? (
        <>
          <BibtexPreview>
            {bibtex ? (
              <Pre>{bibtex}</Pre>
            ) : (
              <>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </>
            )}
          </BibtexPreview>
          <DialogActions>
            <CopyToClipboard text={bibtex} onCopy={(): void => setCopied(true)}>
              <CopyButton
                disabled={!bibtex}
                color={copied ? 'secondary' : 'primary'}
                onClick={(event): void => event.stopPropagation()}
              >
                {copied ? (
                  <>
                    <CheckIcon />
                    Copied
                  </>
                ) : (
                  'Copy'
                )}
              </CopyButton>
            </CopyToClipboard>
          </DialogActions>
        </>
      ) : (
        <BibtexPreview>
          <div>This platform is not supported.</div>
        </BibtexPreview>
      )}
    </Dialog>
  );
};

import Typography from '@material-ui/core/Typography';
import MUIOpenInNewIcon from '@material-ui/icons/OpenInNew';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { toPapers } from '../routing';
import { MyAccessLogType } from '../types/GraphQL';
import * as Util from '../util';

import { AnchorA } from './Anchor';
import { SwitchableCardActionArea } from './SwitchableCardActionArea';

const CardInner = styled.div`
  padding: 1rem;
`;

const PaperTitle = styled.div`
  font-size: 0.9rem;
`;

const OpenInNewIcon = styled(MUIOpenInNewIcon)`
  font-size: 0.6em;
  top: 6px;
`;

const CreatedAt = styled(Typography)`
  margin: 5px 0 0;
  font-size: 0.7rem;
`;

export const AccessedPaperCard: React.FC<{
  paperAccessLog: MyAccessLogType;
}> = ({ paperAccessLog: { paperFull, createdAt } }) => {
  const history = useHistory();
  const paper = paperFull.paperSources?.[0];
  return paper ? (
    <div>
      <SwitchableCardActionArea
        onClick={(event): void => {
          const target = event.target as HTMLElement;
          if (target.tagName !== 'A')
            history.push(toPapers(paper.source, paper.originalID, paper.version));
        }}
      >
        <CardInner>
          <PaperTitle>
            <AnchorA href={paper.originalURL} target="_blank">
              {paper.title || 'No Title'}
              <OpenInNewIcon />
            </AnchorA>
          </PaperTitle>
          <CreatedAt color="textSecondary">{Util.toLocaleString(createdAt)}</CreatedAt>
        </CardInner>
      </SwitchableCardActionArea>
    </div>
  ) : (
    <div>Paper not found</div>
  );
};

import React from 'react';

import { UserContext, userContext, forceUpdateList } from '../../context';
import { useForceUpdate } from '../../hooks';

export const OnlySignedOut: React.FC = ({ children }) => {
  const isSignedOut = !userContext.isSignedIn();

  const forceUpdate = useForceUpdate();

  React.useEffect(() => {
    forceUpdateList.push(forceUpdate);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <UserContext.Provider value={userContext}>{isSignedOut && children}</UserContext.Provider>;
};

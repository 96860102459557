import Typography from '@material-ui/core/Typography';
import React from 'react';
import styled from 'styled-components';

const Content = styled(Typography)`
  margin-top: 35vh;
  text-align: center;
  color: ${({ theme }): string => theme.palette.grey[700]};
`;

export const NotFound: React.FC = () => {
  return <Content variant="h3">404 Not found</Content>;
};

import Avatar from '@material-ui/core/Avatar';
import MUICard from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Scalars } from '../hooks/query';
import { useUserProfile } from '../hooks/user';
import { idToNumber } from '../util';

import { Anchor } from './Anchor';
import { Stats } from './Stats';
import { StatsCharts } from './StatsCharts';
import { StatusStackedBar } from './StatusStackedBar';
import { UserCalendarHeatmap } from './UserCalendarHeatmap';

const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto 20px;
`;

const Card = styled(MUICard)`
  padding: 1rem;
`;

const AvatarAndName = styled.div`
  display: flex;
  align-items: center;
`;

const UserAvatar = styled(Avatar)`
  width: 65px;
  height: 65px;
  margin: 1rem 2rem 1rem 1rem;
`;

const AvatarSkeleton = styled(Skeleton)`
  width: 65px;
  height: 65px;
  margin: 1rem 2rem 1rem 1rem;
`;

const UserInfo = styled.div``;

const UserName = styled(Typography)`
  color: ${({ theme }): string => theme.palette.grey[700]};
  font-weight: bold;
`;

const UserEmail = styled(Typography)`
  color: ${({ theme }): string => theme.palette.grey[600]};
`;

const UserNamesSkeleton = styled.div`
  width: 250px;
`;

const StatsGroup = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  text-align: center;

  & > p {
    margin: 0 0.5rem;

    & a > span {
      display: block;
    }
  }
`;

const UserCalendarHeatmapWrap = styled.div`
  padding: 1.5rem 0 0;
`;

const StatusStackedBarWrap = styled.div`
  padding: 1.5rem 0 0;
`;

const StatsChartWrap = styled.div`
  padding: 1.5rem 0 0;
`;

const UserStatisticsLink = styled.div`
  margin-top: 15px;
  text-align: right;
`;

export const ProfileCard: React.FC<{
  userId: Scalars['ID'];
}> = ({ userId }) => {
  const history = useHistory();

  const { loading, data } = useUserProfile({ userId });

  return (
    <Content>
      <Card variant="outlined">
        {!loading && data ? (
          <>
            <AvatarAndName>
              <UserAvatar />
              <UserInfo>
                <UserName variant="h5">{data.profile.userName ?? 'unknown'}</UserName>
                <UserEmail>{data.profile.email ?? 'unknown'}</UserEmail>
              </UserInfo>
            </AvatarAndName>
            {!loading && (
              <StatsGroup>
                <Stats
                  number={data.counts.read}
                  text="Read"
                  onClick={(): void => history.push(`/${idToNumber(userId)}/papers?mode=read`)}
                />
                <Stats
                  number={data.counts.readLater}
                  text="Read Later"
                  onClick={(): void => history.push(`/${idToNumber(userId)}/papers?mode=readLater`)}
                />
                <Stats
                  number={data.counts.comment}
                  text="Comments"
                  onClick={(): void => history.push(`/${idToNumber(userId)}/comments`)}
                />
                <Stats
                  number={data.counts.favorite}
                  text="Favorites"
                  onClick={(): void => history.push(`/${idToNumber(userId)}/papers?mode=favorite`)}
                />
              </StatsGroup>
            )}
            <StatusStackedBarWrap>
              <StatusStackedBar
                readsCount={data.counts.read}
                readLatersCount={data.counts.readLater}
              />
            </StatusStackedBarWrap>
            <UserCalendarHeatmapWrap>
              <UserCalendarHeatmap userId={userId} />
            </UserCalendarHeatmapWrap>
            <StatsChartWrap>
              <StatsCharts userId={userId} />
            </StatsChartWrap>
            <UserStatisticsLink>
              <Anchor to={`/${idToNumber(data.profile.id)}/statistics`}>Show Statistics</Anchor>
            </UserStatisticsLink>
          </>
        ) : (
          <AvatarAndName>
            <AvatarSkeleton variant="circle" animation="wave" />
            <UserNamesSkeleton>
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" animation="wave" />
            </UserNamesSkeleton>
          </AvatarAndName>
        )}
      </Card>
    </Content>
  );
};

import MuiTab from '@material-ui/core/Tab';
import MuiTabs from '@material-ui/core/Tabs';
import React from 'react';
import styled from 'styled-components';

import { RoutingContext } from '../../context';
import { usePaperIdentifier } from '../../hooks/params';
import { usePaperQuery } from '../../hooks/query';
import { Route } from '../../types/Route';

const Tab = styled(MuiTab)`
  font-size: 11px;
  padding: 6px 0;
`;

const Counter = styled.div`
  margin-top: 4px;
  font-size: 11px;
`;

const tabIndex = {
  [Route.Comments]: 0,
  [Route.Favorites]: 1,
  [Route.Readers]: 2,
  [Route.ReadLaters]: 3,
};

const tabInvertedIndex = [Route.Comments, Route.Favorites, Route.Readers, Route.ReadLaters];

export const Tabs: React.FC<{
  setRoute: (route: Route) => void;
}> = ({ setRoute }) => {
  const route = React.useContext(RoutingContext);
  const { source, originalID } = usePaperIdentifier();
  const { data } = usePaperQuery({
    // TODO: optimize query
    variables: {
      paperInput: {
        source,
        originalID,
      },
    },
  });
  const paperInfo = data?.paper;
  const commentSize = paperInfo ? paperInfo.comments.length : 0;
  const favSize = paperInfo ? paperInfo.favorites.length : 0;
  const readerSize = paperInfo ? paperInfo.readUsers.length : 0;
  const readLaterSize = paperInfo ? paperInfo.readLaterUsers.length : 0;

  return (
    <MuiTabs
      value={tabIndex[route]}
      variant="fullWidth"
      indicatorColor="primary"
      textColor="primary"
      onChange={(_: unknown, newValue: number): void => {
        setRoute(tabInvertedIndex[newValue ?? 0]);
      }}
    >
      <Tab
        label={
          <>
            <div>Comments</div>
            <Counter>{commentSize}</Counter>
          </>
        }
      />
      <Tab
        label={
          <>
            <div>Favorites</div>
            <Counter>{favSize}</Counter>
          </>
        }
      />
      <Tab
        label={
          <>
            <div>Readers</div>
            <Counter>{readerSize}</Counter>
          </>
        }
      />
      <Tab
        label={
          <>
            <div>Read Later</div>
            <Counter>{readLaterSize}</Counter>
          </>
        }
      />
    </MuiTabs>
  );
};

import { ApolloError } from '@apollo/client';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiRadio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { usePrevious } from '../hooks';
import {
  useUserFavoritesQuery,
  useUserReadsQuery,
  useUserReadLatersQuery,
  Scalars,
  PagingInput,
} from '../hooks/query';
import {
  FavoriteConnectionType,
  PaperFavoriteType,
  PaperUserStatusType,
  ReadPaperConnectionType,
} from '../types/GraphQL';
import { idToNumber } from '../util';

import { Cards } from './Cards';
import { FavoritePaperCard } from './FavoritePaperCard';
import { FullBleedButton } from './FullBleedButton';
import { NumberBadge } from './NumberBadge';
import { ReadPaperCard } from './ReadPaperCard';

type Mode = 'favorite' | 'read' | 'readLater';

export const isMode = (target: string | null): target is Mode =>
  target === 'favorite' || target === 'read' || target === 'readLater';

const modeToText: { [k in Mode]: string } = {
  favorite: 'favorite',
  read: 'read',
  readLater: 'read later',
};

const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto 20px;
`;

const Header = styled.div`
  display: flex;
`;

const HeaderTitle = styled.span`
  text-transform: capitalize;
`;

const HeaderLeft = styled.div`
  flex: 1;
`;

const Radio = styled(MuiRadio)`
  padding: 2px 3px 2px 8px;
`;

const HeaderRight = styled.div``;

type GetUsePapersReturn = {
  loading: boolean;
  error: ApolloError | undefined;
  papers?: FavoriteConnectionType | ReadPaperConnectionType;
};
const getUsePapers = (
  mode: Mode
): ((pagingInput: PagingInput, id: Scalars['ID']) => GetUsePapersReturn) => {
  if (mode === 'favorite') {
    return (pagingInput: PagingInput, id: Scalars['ID']): GetUsePapersReturn => {
      const { loading, error, data } = useUserFavoritesQuery({
        variables: { pagingInput, id },
      });
      return { loading, error, papers: data?.user.favorites };
    };
  }
  if (mode === 'read') {
    return (pagingInput: PagingInput, id: Scalars['ID']): GetUsePapersReturn => {
      const { loading, error, data } = useUserReadsQuery({
        variables: { pagingInput, id },
      });
      return { loading, error, papers: data?.user.readPapers };
    };
  }
  return (pagingInput: PagingInput, id: Scalars['ID']): GetUsePapersReturn => {
    const { loading, error, data } = useUserReadLatersQuery({
      variables: { pagingInput, id },
    });
    return { loading, error, papers: data?.user.readLaterPapers };
  };
};

export const FilteredPaperCards: React.FC<{
  user: Scalars['ID'];
  initialMode?: Mode;
  perPage?: number;
  offActions?: boolean;
  truncatedLimit?: number;
}> = ({
  user,
  initialMode = 'favorite',
  perPage = 3,
  offActions = false,
  truncatedLimit = 250,
}) => {
  const history = useHistory();
  const [mode, setMode] = React.useState<Mode>(initialMode);
  const [loadedPapers, setLoadedPapers] = React.useState<
    (PaperFavoriteType | PaperUserStatusType)[]
  >([]);
  const [cursor, setCursor] = React.useState<string | undefined>();
  const { loading, papers } = getUsePapers(mode)(
    {
      first: perPage,
      after: cursor,
    },
    user
  );
  const nextCursor = papers?.pageInfo.endCursor;
  const total = papers?.total ?? 0;
  const favsOrReads = papers?.edge;
  const hasNext = papers?.pageInfo.hasNextPage;
  const hasData = (favsOrReads ?? []).length > 0;

  const load = React.useCallback(() => {
    if (nextCursor) setCursor(nextCursor);
  }, [nextCursor]);

  const handleMode = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newMode = (event.target as HTMLInputElement).value as Mode;
    const query = new URLSearchParams(history.location.search);
    if (query.get('mode')) {
      query.set('mode', newMode);
      history.push({
        search: `?${query.toString()}`,
      });
    }
    setMode(newMode);
  };

  const prevMode = usePrevious(mode);
  React.useEffect(() => {
    const newPapers =
      prevMode !== mode ? favsOrReads ?? [] : [...loadedPapers, ...(favsOrReads ?? [])];
    setLoadedPapers(newPapers);
  }, [favsOrReads, mode]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Content>
      <Cards
        header={
          <Header>
            <HeaderLeft>
              <HeaderTitle>{modeToText[mode]} Papers</HeaderTitle>
              <NumberBadge>{total}</NumberBadge>
            </HeaderLeft>
            <HeaderRight>
              <RadioGroup aria-label="mode" row name="mode" value={mode} onChange={handleMode}>
                <FormControlLabel value="favorite" control={<Radio />} label="Favorite" />
                <FormControlLabel value="read" control={<Radio />} label="Read" />
                <FormControlLabel value="readLater" control={<Radio />} label="Read Later" />
              </RadioGroup>
            </HeaderRight>
          </Header>
        }
        loading={loading && !hasData}
        hasData={hasData}
        noDataText="No papers"
        loadingSkeletonSize={perPage}
        load={offActions ? load : undefined}
        last={!hasNext && offActions}
      >
        {loadedPapers.map((paper) => (
          <div key={`${mode}-${paper.id}`}>
            {mode === 'favorite' && paper.__typename === 'PaperFavorite' && (
              <FavoritePaperCard paperFavorite={paper} truncatedLimit={truncatedLimit} />
            )}
            {(mode === 'read' || mode === 'readLater') &&
              paper.__typename === 'PaperUserStatus' && (
                <ReadPaperCard paperRead={paper} truncatedLimit={truncatedLimit} />
              )}
            <Divider />
          </div>
        ))}

        {!offActions && hasData && (
          <CardActions>
            <FullBleedButton
              color="primary"
              endIcon={<ArrowForwardIcon />}
              onClick={(): void => history.push(`/${idToNumber(user)}/papers?mode=${mode}`)}
            >
              All {mode} Papers
            </FullBleedButton>
          </CardActions>
        )}
      </Cards>
    </Content>
  );
};

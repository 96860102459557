import React from 'react';

import { UserContext } from '../context';
import { numberToId } from '../util';

import { User } from './query';

type ForceUpdater = (() => void) & { value: number };

export const useForceUpdate = (): ForceUpdater => {
  const [value, setValue] = React.useState(Date.now()); // eslint-disable-line @typescript-eslint/no-unused-vars
  return Object.assign((): void => setValue(Date.now()), { value });
};

export const useIsMyComment = (): ((user: User) => boolean) => {
  const { session } = React.useContext(UserContext);
  return React.useCallback(
    (user: User): boolean => user?.id === numberToId('User', session.userId),
    [session]
  );
};

// prettier-ignore
export const usePrevious = (value: any): any => { // eslint-disable-line @typescript-eslint/no-explicit-any
  const ref = React.useRef(null);
  React.useEffect((): void => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

import Divider from '@material-ui/core/Divider';
import GroupIcon from '@material-ui/icons/Group';
import SecurityIcon from '@material-ui/icons/Security';
import React from 'react';
import styled from 'styled-components';

import { Tag, useTagPapersQuery, TagPapersQuery } from '../hooks/query';
import { isTeamTag, isUserTag, normalizeTagName } from '../util';

import { Cards } from './Cards';
import { NumberBadge } from './NumberBadge';
import { PaperCardContent } from './PaperCardContent';

const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto 20px;
`;

const Header = styled.div`
  display: flex;
`;

const HeaderTitle = styled.span`
  display: flex;
  align-items: center;

  & svg {
    margin-right: 5px;
  }
`;

export const TagPaperCards: React.FC<{
  tagName: Tag['name'];
  perPage?: number;
  truncatedLimit?: number;
}> = ({ tagName, perPage = 3, truncatedLimit = 250 }) => {
  const [loadedPapers, setLoadedPapers] = React.useState<TagPapersQuery['tag']['papers']['edge']>(
    []
  );
  const [cursor, setCursor] = React.useState<string | undefined>();
  const { loading, data } = useTagPapersQuery({
    variables: {
      name: tagName,
      pagingInput: {
        first: perPage,
        after: cursor,
      },
    },
  });
  const papers = data?.tag.papers;
  const nextCursor = papers?.pageInfo.endCursor;
  const total = papers?.total ?? 0;
  const hasNext = papers?.pageInfo.hasNextPage;
  const hasData = (papers?.edge ?? []).length > 0;

  const load = React.useCallback(() => {
    if (nextCursor) setCursor(nextCursor);
  }, [nextCursor]);

  React.useEffect(() => {
    const newPapers = [...loadedPapers, ...(papers?.edge ?? [])];
    setLoadedPapers(newPapers);
  }, [papers]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Content>
      <Cards
        header={
          <Header>
            <HeaderTitle>
              {isUserTag(tagName) ? (
                <SecurityIcon fontSize="small" />
              ) : isTeamTag(tagName) ? (
                <GroupIcon fontSize="small" />
              ) : (
                ''
              )}
              #{normalizeTagName(tagName)}{' '}
            </HeaderTitle>
            <NumberBadge>{total}</NumberBadge>
          </Header>
        }
        loading={loading && !hasData}
        hasData={hasData}
        noDataText="No papers"
        loadingSkeletonSize={perPage}
        load={load}
        last={!hasNext}
      >
        {loadedPapers.map(({ paper, tags }) => (
          <div key={paper.originalID}>
            <PaperCardContent paper={paper} tags={tags} truncatedLimit={truncatedLimit} />
            <Divider />
          </div>
        ))}
      </Cards>
    </Content>
  );
};

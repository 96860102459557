import { Grid } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { isTeamTag, isUserTag } from '../util';

import { TagEventCards } from './TagEventCards';
import { TagPaperCards } from './TagPaperCards';
import { TagSlackChannelCards } from './TagSlackChannelCards';

const Content = styled.div`
  max-width: 1200px;
  margin: 0px auto;
  padding-top: 18px;
`;

export const TagPapers: React.FC = () => {
  const { tagName } = useParams<{ tagName: string }>();

  return (
    <Content>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <TagPaperCards tagName={tagName} perPage={100} />
        </Grid>
        <Grid item xs={12} md={3}>
          {(isUserTag(tagName) || isTeamTag(tagName)) && (
            <TagSlackChannelCards tagName={tagName} perPage={5} />
          )}
          <TagEventCards tagName={tagName} perPage={5} />
        </Grid>
      </Grid>
    </Content>
  );
};

import { Button } from '@material-ui/core';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import React from 'react';
import styled from 'styled-components';

import { cachedPdfUrl } from '../../api';
import { usePaperIdentifier } from '../../hooks/params';
import { Paper_Source_Type } from '../../hooks/query';

const PdfIcon = styled(FlashOnIcon)`
  font-size: 0.8rem;
`;

export const PdfLinkButton: React.FC = () => {
  const { source, originalID } = usePaperIdentifier();

  if (source !== Paper_Source_Type.ArxivOrg) {
    return null;
  }

  return (
    <Button
      style={{ minWidth: 0 }}
      size="small"
      color="inherit"
      href={cachedPdfUrl({ source, originalID })}
      target="_blank"
      rel="noreferrer"
    >
      <PdfIcon fontSize="small" />
      PDF
    </Button>
  );
};

import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import styled from 'styled-components';

const Skeletons = styled.div`
  padding: 0.5rem 1rem;
`;

const ShortSkeleton = styled(Skeleton)`
  width: 50%;
`;

export const SkeletonCard: React.FC<{ size: number }> = ({ size = 4 }) => (
  <Skeletons>
    {[...Array(size).keys()].map((k) =>
      k + 1 === size ? (
        <ShortSkeleton key={k} animation="wave" />
      ) : (
        <Skeleton key={k} animation="wave" />
      )
    )}
  </Skeletons>
);

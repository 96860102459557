import { DateTime } from 'luxon';
import React from 'react';
import useMeasure from 'react-use-measure';
import { AreaChart, Area, Tooltip, XAxis, YAxis } from 'recharts';
import styled from 'styled-components';

import { StatisticsData, useUserDateStatistics } from '../hooks/user';
import { theme as Theme } from '../theme';

const Content = styled.div`
  width: 100%;
`;

const Title = styled.div`
  color: ${({ theme }): string => theme.palette.grey['700']};
`;

export const StatsChart: React.FC<{ title: string; data: StatisticsData }> = ({ title, data }) => {
  const [ref, { width }] = useMeasure();

  const range = 30;
  const endDate = React.useMemo(() => DateTime.local(), []);
  const startDate = React.useMemo(() => endDate.minus({ days: range }), [endDate]);
  const value = useUserDateStatistics({
    data,
    dateRange: { start: startDate, end: endDate },
    cumulative: true,
  });

  return (
    <Content ref={ref}>
      <Title>{title}</Title>
      <AreaChart
        width={width}
        height={60}
        data={value.slice(-range)}
        margin={{
          top: 5,
          right: 0,
          left: 0,
          bottom: 5,
        }}
      >
        <Tooltip />
        <XAxis dataKey="date" hide />
        <YAxis dataKey="value" hide domain={['auto', 'auto']} />
        <Area
          type="monotone"
          dataKey="value"
          stroke={Theme.palette.primary.light}
          fill={Theme.palette.primary.light}
        />
      </AreaChart>
    </Content>
  );
};

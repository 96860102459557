import React from 'react';

import { Scalars, UserNotificationType } from '../hooks/query';
import { PaperCommentType } from '../types/GraphQL';

import { PaperCommentCard } from './PaperCommentCard';

export const NotificationCard: React.FC<{
  notification: {
    type: UserNotificationType;
    id: Scalars['ID'];
    newCommentForPaper?: PaperCommentType | null;
  };
  truncatedLimit?: number;
}> = ({ notification, truncatedLimit = 200 }) => {
  return (
    <div>
      {notification.type === UserNotificationType.NewCommentForPaper &&
        notification.newCommentForPaper && (
          <PaperCommentCard
            paperComment={notification.newCommentForPaper}
            truncatedLimit={truncatedLimit}
          />
        )}
      {notification.type === UserNotificationType.NewCommentForPaper &&
        !notification.newCommentForPaper && <div>No comment to show.</div>}
      {notification.type !== UserNotificationType.NewCommentForPaper && (
        <div>this notification type is not supported.</div>
      )}
    </div>
  );
};

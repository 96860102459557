import { DateTime } from 'luxon';
import React from 'react';

import { toDateString } from '../util';

import { useUserProfileQuery, Scalars, UserProfileQuery, useUserStatsQuery } from './query';

export type StatisticsData = {
  eventAt: string;
}[];

export const useUserProfile = ({
  userId,
}: {
  userId: Scalars['ID'];
}): {
  loading: boolean;
  data: {
    profile: UserProfileQuery['user']['profile'];
    counts: {
      comment: number;
      favorite: number;
      read: number;
      readLater: number;
    };
  } | null;
} => {
  const { loading, data } = useUserProfileQuery({ variables: { id: userId } });
  if (loading || !data) return { loading, data: null };

  return {
    loading,
    data: {
      profile: data.user.profile,
      counts: {
        comment: data.user.comments.total,
        favorite: data.user.favorites.total,
        read: data.user.readPapers.total,
        readLater: data.user.readLaterPapers.total,
      },
    },
  };
};

export type DateRange = {
  start: DateTime;
  end: DateTime;
};

export const useUserStatistics = ({
  userId,
  dateRange,
}: {
  userId: Scalars['ID'];
  dateRange?: DateRange;
}): {
  loading: boolean;
  data: {
    comments: StatisticsData;
    favorites: StatisticsData;
    reads: StatisticsData;
    readLaters: StatisticsData;
  } | null;
} => {
  const startDate = React.useMemo(
    () => dateRange?.start.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
    [dateRange?.start]
  );
  const endDate = React.useMemo(
    () => dateRange?.end.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
    [dateRange?.end]
  );

  const { loading, data } = useUserStatsQuery({ variables: { id: userId } });
  if (loading || !data) return { loading, data: null };

  const filter = ({ eventAt }: StatisticsData[number]): boolean => {
    if (!startDate || !endDate) return true;
    const dateTime = DateTime.fromISO(eventAt);
    return startDate <= dateTime && dateTime < endDate.plus({ days: 1 });
  };

  return {
    loading,
    data: {
      comments: data.user.comments.edge.map((d) => ({ eventAt: d.createdAt })).filter(filter),
      favorites: data.user.favorites.edge.map((d) => ({ eventAt: d.createdAt })).filter(filter),
      reads: data.user.readPapers.edge.map((d) => ({ eventAt: d.changedAt })).filter(filter),
      readLaters: data.user.readLaterPapers.edge
        .map((d) => ({ eventAt: d.changedAt }))
        .filter(filter),
    },
  };
};

type DateStatisticsData = {
  date: string;
  value: number;
}[];

export const useUserDateStatistics = ({
  data,
  dateRange,
  cumulative,
}: {
  data: StatisticsData;
  dateRange: DateRange;
  cumulative?: boolean;
}): DateStatisticsData => {
  const [value, setValue] = React.useState<DateStatisticsData>([]);

  const startDate = React.useMemo(
    () => dateRange.start.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
    [dateRange.start]
  );
  const endDate = React.useMemo(
    () => dateRange.end.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
    [dateRange.end]
  );

  const diff = endDate.diff(startDate, 'days').days;
  const range = diff > 0 ? diff : 0;
  const dateList = React.useMemo(() => {
    return [...Array(range)].map((_, i) => startDate.plus({ days: i + 1 }));
  }, [range, startDate]);

  React.useEffect(() => {
    const converted = Object.entries(
      data
        .map((i) => ({
          date: toDateString(i.eventAt),
          value: 1,
        }))
        .reduce<{ [K: string]: number }>(
          (prev, obj) => ({
            ...prev,
            [obj.date]: obj.date in prev ? prev[obj.date] + obj.value : obj.value,
          }),
          {}
        )
    )
      .map(([date, v]) => ({ date, value: v }))
      .sort((a, b) => a.date.localeCompare(b.date))
      .filter((d) => {
        const dateTime = DateTime.fromISO(d.date);
        return startDate <= dateTime && dateTime < endDate.plus({ days: 1 });
      })
      .reduce<DateStatisticsData>(
        // accumulate value
        (prev, curr) => [
          ...prev,
          {
            date: curr.date,
            value: cumulative
              ? curr.value + (prev.length === 0 ? 0 : prev[prev.length - 1].value)
              : curr.value,
          },
        ],
        []
      )
      .reduce<{ [K: string]: number }>((prev, curr) => ({ ...prev, [curr.date]: curr.value }), {});
    let lastValue = 0;
    const dateListWithValue = dateList.map((date) => {
      if (cumulative) {
        lastValue = converted[date.toISODate()] ?? lastValue;
      }
      return {
        date: date.toISODate(),
        value: converted[date.toISODate()] ?? lastValue,
      };
    });
    setValue(dateListWithValue);
  }, [cumulative, data, dateList, endDate, startDate]);

  return value;
};

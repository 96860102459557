import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import styled from 'styled-components';

import { useAuth } from '../hooks/auth';

const Display = styled.span`
  display: ${({ hidden }): string => (hidden ? 'none' : 'block')};
`;

const ButtonProgress = styled(CircularProgress)`
  position: absolute;
  top: -50%;
`;

export const SignInButton: React.FC = () => {
  const { signIn, status } = useAuth();
  const loading = status === 'authorizing';

  return (
    <div>
      <Button onClick={signIn} color="primary">
        {loading ? <ButtonProgress size={20} /> : <Display hidden={loading}>Sign in</Display>}
      </Button>
    </div>
  );
};

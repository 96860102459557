import { DateTime } from 'luxon';

import { Paper_Source_Type } from './hooks/query';

export const isProduction = (): boolean => process.env.NODE_ENV === 'production';

export const isDevelopment = (): boolean => process.env.NODE_ENV === 'development';

export const truncate = (target: string, len = 30, ellipsis = '...'): string => {
  return target.substr(0, len) + ellipsis;
};

export const openNewTab = (url: string): void => {
  window.open(url, '_blank', 'noopener');
};

export const openPortal = (path = ''): void => {
  const url = isProduction() ? 'https://go.pfn.io/paper-curation' : 'https://localhost:3000';
  openNewTab(url + path);
};
export const openPortalDashboard = (userId: number): void => {
  openPortal(`/dashboard/${userId}`);
};

export const openChromeExtensionPage = (): void => {
  const url = 'https://go.pfn.io/paper-curation-extension';
  openNewTab(url);
};

export const openGithubPage = (): void => {
  const url = 'https://github.pfidev.jp/paper-curation';
  openNewTab(url);
};

export const toLocaleString = (dateString: string): string => new Date(dateString).toLocaleString();

export const idToNumber = (id: string): number => parseInt(id.split(':')[1], 10);

type ModelName = 'PaperComment' | 'User';
export const numberToId = (modelName: ModelName, numberId: number | string): string =>
  `${modelName}:${numberId}`;

const sourceMap: {
  [K in Exclude<Source, 'unknown'>]: Paper_Source_Type;
} = {
  arxiv: Paper_Source_Type.ArxivOrg,
  biorxiv: Paper_Source_Type.Biorxiv,
  medrxiv: Paper_Source_Type.Medrxiv,
  openreview: Paper_Source_Type.Openreview,
  semanticScholar: Paper_Source_Type.SemanticScholar,
  doi: Paper_Source_Type.Doi,
  chemrxiv: Paper_Source_Type.Chemrxiv,
  ieee: Paper_Source_Type.IEEE,
};

const sourceMapRev = Object.entries(sourceMap).reduce(
  (prev, [a, b]) => ({ ...prev, [b]: a }),
  {}
) as { [K in Paper_Source_Type]: Source };

export const sourceToPaperSourceType = (source: Exclude<Source, 'unknown'>): Paper_Source_Type =>
  sourceMap[source];

export const paperSourceTypeToSource = (source: Paper_Source_Type): Source => sourceMapRev[source];

export const normalizeTagName = (tagName: string): string => {
  if (tagName.startsWith('user:')) return tagName.split(':')[2];
  if (tagName.startsWith('team:')) return tagName.split(':')[1];
  return tagName;
};

export const isUserTag = (tagName: string): boolean => tagName.startsWith('user:');

export const isTeamTag = (tagName: string): boolean => tagName.startsWith('team:');

export const isSharedTag = (tagName: string): boolean => !isUserTag(tagName) && !isTeamTag(tagName);

export const toDateString = (isoString: string): string => DateTime.fromISO(isoString).toISODate();

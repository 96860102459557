import React from 'react';
import ReactMarkdown from 'react-markdown';
// import MathJax2 from 'react-mathjax2';
import MathJax from 'react-mathjax';
import Gmf from 'remark-gfm';
import RemarkMathPlugin from 'remark-math';
import { useDebounce } from 'use-debounce';
import useInterval from 'use-interval';

const linkRenderer: React.FC<{ href: string }> = ({ children, href }) => (
  <a href={href} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);

export const Markdown: React.FC<{
  text: string;
  renderers?: { [nodeType: string]: React.ReactType };
}> = ({ text, renderers }) => {
  const [debouncedText] = useDebounce(text, 1000);
  const [loading, setLoading] = React.useState(true);

  const newProps = {
    source: debouncedText,
    plugins: [RemarkMathPlugin, Gmf],
    renderers: {
      ...(renderers ?? {}),
      // eslint-disable-next-line react/display-name
      math: (p: { value: string }): React.ReactElement => <MathJax.Node formula={p.value} />,
      // eslint-disable-next-line react/display-name
      inlineMath: (p: { value: string }): React.ReactElement => (
        <MathJax.Node inline formula={p.value} />
      ),
      link: linkRenderer,
    },
  };

  useInterval(() => {
    setLoading(false);
  }, 100);

  return (
    <>
      {!loading && (
        <MathJax.Provider>
          <ReactMarkdown
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...newProps}
            className="markdown-body"
          />
        </MathJax.Provider>
      )}
    </>
  );
};

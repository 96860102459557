import Typography from '@material-ui/core/Typography';
import React from 'react';
import styled, { css } from 'styled-components';

const StatsItem = styled(Typography)`
  color: ${({ theme }): string => theme.palette.grey[600]};
`;

const StatsNumber = styled.span`
  display: inline-block;
  margin-right: 0.2rem;
  font-size: 1.5em;
  color: ${({ theme }): string => theme.palette.primary.main};
`;

const ClickableMixin = css`
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    border-bottom: 1px solid;
    border-color: ${({ theme }): string => theme.palette.primary.main};
  }
`;

const StatsLink = styled.a<{ clickable: boolean }>`
  display: inline-block;
  text-decoration: none;
  line-height: 18px;
  ${({ clickable }): typeof ClickableMixin | string => (clickable ? ClickableMixin : '')}
`;

export const Stats: React.FC<{
  number: number;
  text: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}> = ({ number, text, onClick }) => {
  return (
    <StatsItem>
      <StatsLink clickable={Boolean(onClick)} onClick={(event): void => onClick?.(event)}>
        <StatsNumber>{number}</StatsNumber>
        {text}
      </StatsLink>
    </StatsItem>
  );
};

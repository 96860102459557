import React from 'react';
import styled from 'styled-components';

import { usePaperIdentifier } from '../../hooks/params';
import { useDeletePaperTagMutation, usePaperQuery } from '../../hooks/query';
import { isSharedTag, isTeamTag, isUserTag } from '../../util';
import { Tag } from '../Tag';
import { TagAdder } from '../TagAdder';

const Content = styled.div`
  position: relative;
  width: 100%;
  height: 40px;
  border-bottom: solid 1px ${({ theme }): string => theme.palette.grey[300]};
`;

const TagsInner = styled.div`
  white-space: nowrap;
  padding: 8px 5px;
  width: calc(100% - 28px);
  overflow-x: auto;

  & > div {
    margin-right: 5px;
  }
`;

const AddMessage = styled.div`
  text-align: center;
  font-weight: bold;
  line-height: 25px;
  color: ${({ theme }): string => theme.palette.secondary.main};
`;

const TagAdderWrap = styled.div`
  position: absolute;
  top: 7px;
  right: 3px;
`;

export const Tags: React.FC = () => {
  const { source, originalID, version } = usePaperIdentifier();

  const paperInput = React.useMemo(
    () => ({
      source,
      originalID,
      version,
    }),
    [originalID, source, version]
  );

  const { data } = usePaperQuery({
    variables: {
      paperInput,
    },
  });

  const paperInfo = data?.paper;

  const userTags = (paperInfo?.tags.filter((tag) => isUserTag(tag.name)) ?? []).sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  const teamTags = (paperInfo?.tags.filter((tag) => isTeamTag(tag.name)) ?? []).sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  const sharedTags = (paperInfo?.tags.filter((tag) => isSharedTag(tag.name)) ?? []).sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const [deletePaperTag] = useDeletePaperTagMutation({ refetchQueries: ['Paper', 'MyTags'] });

  const deletePaperTagHandler = React.useCallback(
    (tagName) => {
      void deletePaperTag({ variables: { paperInput, name: tagName } });
    },
    [deletePaperTag, paperInput]
  );

  return (
    <Content>
      <TagsInner>
        {userTags.length === 0 && teamTags.length === 0 && sharedTags.length === 0 && (
          <AddMessage>Please add tags for this paper!</AddMessage>
        )}
        {userTags?.map((tag) => (
          <Tag key={tag.id} tagName={tag.name} openLinkNewTab onDelete={deletePaperTagHandler} />
        ))}
        {teamTags?.map((tag) => (
          <Tag key={tag.id} tagName={tag.name} openLinkNewTab onDelete={deletePaperTagHandler} />
        ))}
        {sharedTags?.map((tag) => (
          <Tag key={tag.id} tagName={tag.name} openLinkNewTab onDelete={deletePaperTagHandler} />
        ))}
      </TagsInner>
      <TagAdderWrap>
        <TagAdder
          mode="user"
          paperInput={paperInput}
          existingTags={[...userTags, ...teamTags, ...sharedTags]}
          isExtension
        />
      </TagAdderWrap>
    </Content>
  );
};

import Typography from '@material-ui/core/Typography';
import React from 'react';
import styled from 'styled-components';

import { PaperUserStatusType } from '../types/GraphQL';
import * as Util from '../util';

import { PaperCardContent } from './PaperCardContent';

const CreatedAt = styled(Typography)`
  margin-bottom: 0;
  text-align: right;
  font-size: 0.7rem;
`;

export const ReadPaperCard: React.FC<{
  paperRead: PaperUserStatusType;
  truncatedLimit?: number;
}> = ({ paperRead, truncatedLimit = 300 }) => {
  const paper = paperRead.paperFull.paperSources?.[0];
  return paper ? (
    <div>
      <PaperCardContent
        paper={paper}
        tags={paperRead.paperFull.tags}
        truncatedLimit={truncatedLimit}
        footer={
          <CreatedAt color="textSecondary">
            Added at {Util.toLocaleString(paperRead.changedAt)}
          </CreatedAt>
        }
      />
    </div>
  ) : (
    <div>Paper not found</div>
  );
};

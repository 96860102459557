import { Box, Button } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Variant } from '@material-ui/core/styles/createTypography';
import MUIOpenInNewIcon from '@material-ui/icons/OpenInNew';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { cachedPdfUrl } from '../api';
import { Paper, PaperInput, PaperSource, PaperTag, PaperUserStatusName } from '../hooks/query';
import { toPapers } from '../routing';
import { isSharedTag, isTeamTag, isUserTag } from '../util';

import { Anchor, AnchorA } from './Anchor';
import { AuthorAnchors } from './AuthorAnchors';
import { FavoriteButton } from './FavoriteButton';
import { PaperStatusButton } from './PaperStatusButton';
import { PdfAnchor } from './PdfAnchor';
import { StopPropagation } from './StopPropagation';
import { SwitchableCardActionArea } from './SwitchableCardActionArea';
import { Tag } from './Tag';
import { TextExpander } from './TextExpander';
import { BibtexDialog } from './extension/BibtexDialog';

const Content = styled.div`
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
`;

const FavoriteButtonWrap = styled.div`
  margin-right: 10px;
`;

const PaperStatusButtonWrap = styled.div``;

const PaperTitle = styled(Typography)`` as typeof Typography;

const PaperAbstract = styled(Typography)`
  margin-top: 15px;
` as typeof Typography;

const PaperAuthors = styled(Typography)`
  margin-top: 3px;
`;

const OpenInNewIcon = styled(MUIOpenInNewIcon)`
  font-size: 0.6em;
  top: 6px;
`;

const Tags = styled.div`
  margin-top: 10px;

  & > div {
    margin: 0 10px 10px 0;
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const PaperCardContent: React.FC<{
  paper: PaperSource | Paper;
  tags?: PaperTag[];
  titleVariant?: Variant;
  footer?: React.ReactElement;
  truncatedLimit?: number;
  noAction?: boolean;
  originalPageTitleLink?: boolean;
  isFavorite?: boolean;
  paperStatus?: PaperUserStatusName;
  onChange?: () => void;
}> = ({
  paper,
  tags = [],
  titleVariant = 'h5',
  footer,
  truncatedLimit = 300,
  noAction = false,
  originalPageTitleLink = false,
  isFavorite,
  paperStatus,
  onChange = (): void => {},
}) => {
  const history = useHistory();
  const [openBibtex, setOpenBibtex] = React.useState(false);

  const paperInput: PaperInput = React.useMemo(
    () => ({ source: paper.source, originalID: paper.originalID, version: paper.version }),
    [paper]
  );

  const handleBibtexOpen = React.useCallback((event) => {
    event.stopPropagation();
    setOpenBibtex(true);
  }, []);

  const handleBibtexClose = React.useCallback((event) => {
    event.stopPropagation();
    setOpenBibtex(false);
  }, []);

  const userTags = tags
    .filter(({ name }) => isUserTag(name))
    .sort((a, b) => a.name.localeCompare(b.name));
  const teamTags = tags
    .filter(({ name }) => isTeamTag(name))
    .sort((a, b) => a.name.localeCompare(b.name));
  const sharedTags = tags
    .filter(({ name }) => isSharedTag(name))
    .sort((a, b) => a.name.localeCompare(b.name));

  const sortedTags = [...userTags, ...teamTags, ...sharedTags];

  return (
    <Content>
      <SwitchableCardActionArea
        noAction={noAction}
        onClick={(event): void => {
          const target = event.target as HTMLElement;
          if (target.tagName !== 'A')
            history.push(toPapers(paper.source, paper.originalID, paper.version));
        }}
      >
        <CardContent>
          <Header>
            {paperStatus && (
              <PaperStatusButtonWrap>
                <PaperStatusButton
                  paperInput={paperInput}
                  variant="outlined"
                  status={paperStatus}
                  onChange={onChange}
                />
              </PaperStatusButtonWrap>
            )}
            {typeof isFavorite === 'boolean' && (
              <FavoriteButtonWrap>
                <FavoriteButton
                  variant="outlined"
                  paperInput={paperInput}
                  isFavorite={isFavorite}
                  onChange={onChange}
                />
              </FavoriteButtonWrap>
            )}
          </Header>
          <PaperTitle variant={titleVariant} component="h2">
            <StopPropagation fitContent>
              {originalPageTitleLink ? (
                <AnchorA href={paper.originalURL} target="_blank" rel="noopener noreferrer">
                  {paper.title || 'No Title'}
                  <OpenInNewIcon />
                </AnchorA>
              ) : (
                <Anchor to={toPapers(paper.source, paper.originalID, paper.version)}>
                  {paper.title || 'No Title'}
                </Anchor>
              )}
              <PdfAnchor
                url={cachedPdfUrl({ source: paper.source, originalID: paper.originalID })}
              />
            </StopPropagation>
          </PaperTitle>
          <PaperAuthors variant="subtitle2">
            <Box fontWeight="fontWeightRegular">
              <AuthorAnchors authors={paper.authors ?? []} />
            </Box>
          </PaperAuthors>
          <PaperAbstract variant="body1" component="div">
            <TextExpander text={paper.abstract ?? ''} truncatedLimit={truncatedLimit} />
          </PaperAbstract>
          <StopPropagation>
            <Tags>
              {sortedTags.map((tag) => (
                <Tag key={tag.id} tagName={tag.name} />
              ))}
            </Tags>
          </StopPropagation>
          <Footer>
            <Buttons>
              <Button
                color="primary"
                component="a"
                size="small"
                variant="text"
                onClick={handleBibtexOpen}
              >
                Bibtex
              </Button>
            </Buttons>
            {footer}
          </Footer>
        </CardContent>
      </SwitchableCardActionArea>
      <StopPropagation>
        <BibtexDialog paperInput={paperInput} open={openBibtex} onClose={handleBibtexClose} />
      </StopPropagation>
    </Content>
  );
};

import React from 'react';

import { Anchor } from './Anchor';

export const AuthorAnchors: React.FC<{
  authors: string[];
}> = ({ authors }) => (
  <>
    {(authors ?? []).map((author, index) => (
      <Anchor
        key={author}
        offVisited
        to={`https://arxiv.org/search/cs?searchtype=author&query=${author}`}
        colorFunction={({ theme }): string => theme.palette.text.secondary}
        target="_blank"
      >
        {author}
        {index + 1 !== authors.length && ', '}
      </Anchor>
    ))}
  </>
);

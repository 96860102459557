import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useQuery } from '../hooks/params';
import { numberToId } from '../util';

import { FilteredPaperCards, isMode } from './FilteredPaperCards';

const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 18px;
`;

export const Papers: React.FC = () => {
  const { userId: userIdRaw } = useParams<{ userId: string }>();
  const query = useQuery();
  const modeFromQuery = query.get('mode');
  const mode = isMode(modeFromQuery) ? modeFromQuery : 'favorite';
  const userId = parseInt(userIdRaw, 10);
  const userStringId = numberToId('User', userId);

  return (
    <Content>
      <FilteredPaperCards
        user={userStringId}
        initialMode={mode}
        offActions
        perPage={100}
        truncatedLimit={400}
      />
    </Content>
  );
};

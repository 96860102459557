import React from 'react';
import styled from 'styled-components';

const Content = styled.div<{ fitContent: boolean }>`
  width: ${({ fitContent }): string => (fitContent ? 'fit-content' : 'auto')};
`;

export const StopPropagation: React.FC<{ fitContent?: boolean }> = ({
  children,
  fitContent = false,
}) => (
  <Content fitContent={fitContent} onClick={(event): void => event.stopPropagation()}>
    {children}
  </Content>
);

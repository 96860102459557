import Typography from '@material-ui/core/Typography';
import React from 'react';
import styled from 'styled-components';

const CardInner = styled.div`
  padding: 1rem;
`;

export const NoDataCard: React.FC<{
  text?: string;
}> = ({ text = 'No Data.' }) => {
  return (
    <div>
      <CardInner>
        <Typography color="textSecondary">{text}</Typography>
      </CardInner>
    </div>
  );
};

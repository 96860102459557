import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountCircle from '@material-ui/icons/AccountCircle';
import React from 'react';
import styled from 'styled-components';

import { usePaperIdentifier } from '../../hooks/params';
import { usePaperQuery } from '../../hooks/query';
import * as Util from '../../util';

const Content = styled.div`
  width: 100%;
  height: calc(100vh - 143px);
  overflow-y: auto;
`;

export const Readers: React.FC = () => {
  const { source, originalID } = usePaperIdentifier();
  const { data } = usePaperQuery({
    // TODO: optimize query
    variables: {
      paperInput: {
        source,
        originalID,
      },
    },
  });
  const paperInfo = data?.paper;

  return (
    <Content>
      <List>
        {paperInfo?.readUsers.map(({ user }) => (
          <ListItem
            key={user.email}
            button
            onClick={(): void => Util.openPortalDashboard(Util.idToNumber(user.id))}
          >
            <ListItemIcon>
              <AccountCircle />
            </ListItemIcon>
            <ListItemText primary={`${user.userName} (${user.email})`} />
          </ListItem>
        ))}
      </List>
    </Content>
  );
};

import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { useWeeklyRankingsQuery } from '../hooks/query';

import { Cards } from './Cards';
import { FullBleedButton } from './FullBleedButton';
import { RankingPaperCard } from './RankingPaperCard';

const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto 20px;
`;

const RankingSelector = styled.div`
  float: right;
  font-size: 14px;
  display: flex;
  line-height: 25px;
  margin-top: 2px;
`;

const RecordDate = styled.div`
  width: 100px;
  text-align: center;
`;

const MoveRanking = styled.div`
  padding: 0 5px;
  height: 24px;
  cursor: pointer;
`;

const DummyButton = styled.div`
  width: 34px;
`;

export const RankingPaperCards: React.FC<{
  perPage?: number;
  offActions?: boolean;
  truncatedLimit?: number;
}> = ({ perPage = 10, offActions = false, truncatedLimit = 250 }) => {
  const history = useHistory();
  const [index, setIndex] = React.useState(0);
  const { loading, data } = useWeeklyRankingsQuery({
    variables: {
      pagingInput: {
        first: 10,
      },
    },
  });
  const rankings = data?.weeklyRankings.edge ?? [];
  const hasData = rankings.length > 0;
  const hasPrev = index < rankings.length - 1;
  const hasNext = index > 0;
  const ranking = rankings[index];

  return (
    <Content>
      <Cards
        header={
          <>
            Weekly Ranking
            <RankingSelector>
              {hasPrev ? (
                <MoveRanking onClick={(): void => setIndex((ind) => ind + 1)}>
                  <ChevronLeft />
                </MoveRanking>
              ) : (
                <DummyButton />
              )}
              <RecordDate>{ranking?.recordDate}</RecordDate>
              {hasNext ? (
                <MoveRanking onClick={(): void => setIndex((ind) => ind - 1)}>
                  <ChevronRight />
                </MoveRanking>
              ) : (
                <DummyButton />
              )}
            </RankingSelector>
          </>
        }
        loading={loading}
        hasData={hasData}
        noDataText="No paper"
        loadingSkeletonSize={perPage}
        last={offActions}
      >
        {hasData &&
          ranking.records.slice(0, perPage).map((record) => (
            <div key={record.paperFull.paperSources?.[0].originalID}>
              <RankingPaperCard
                rank={record.order}
                score={record.score}
                paperFull={record.paperFull}
                truncatedLimit={truncatedLimit}
              />
              <Divider />
            </div>
          ))}

        {!offActions && hasData && (
          <CardActions>
            <FullBleedButton
              color="primary"
              endIcon={<ArrowForwardIcon />}
              onClick={(): void => history.push('/rankings')}
            >
              All Ranking
            </FullBleedButton>
          </CardActions>
        )}
      </Cards>
    </Content>
  );
};

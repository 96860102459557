import { Theme } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const AnchorA = styled.a<{
  offVisited?: boolean;
  colorFunction?: ({ theme }: { theme: Theme }) => string;
}>`
  text-decoration: none;
  color: ${({ theme, colorFunction }): string =>
    colorFunction?.({ theme }) ?? theme.palette.primary.main};
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
  }

  &:visited {
    color: ${({ theme, offVisited, colorFunction }): string =>
      offVisited ? colorFunction?.({ theme }) ?? theme.palette.primary.main : '#7d239a'};
  }
`;

export const AnchorLink = styled(Link)<{
  offVisited?: boolean;
  colorFunction?: ({ theme }: { theme: Theme }) => string;
}>`
  text-decoration: none;
  color: ${({ theme, colorFunction }): string =>
    colorFunction?.({ theme }) ?? theme.palette.primary.main};
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
  }

  &:visited {
    color: ${({ theme, offVisited, colorFunction }): string =>
      offVisited ? colorFunction?.({ theme }) ?? theme.palette.primary.main : '#7d239a'};
  }
`;

export const Anchor: React.FC<{
  className?: string;
  target?: '_blank';
  to: string;
  offVisited?: boolean;
  colorFunction?: ({ theme }: { theme: Theme }) => string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}> = ({
  className = '',
  target,
  to,
  offVisited = false,
  colorFunction,
  onClick = () => {},
  children,
}) => (
  <AnchorLink
    className={className}
    to={to}
    target={target}
    onClick={onClick}
    offVisited={offVisited}
    colorFunction={colorFunction}
    rel={target === '_blank' ? 'noopener noreferrer' : ''}
  >
    {children}
  </AnchorLink>
);

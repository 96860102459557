import { ApolloClient, InMemoryCache } from '@apollo/client';

import * as Util from './util';

const API_SERVER = Util.isProduction()
  ? process.env.REACT_APP_API_SERVER
  : process.env.REACT_APP_API_SERVER_DEV;

export const client = new ApolloClient({
  uri: `${API_SERVER}/api/v3/graphql/query/`,
  cache: new InMemoryCache(),
  credentials: 'include',
});

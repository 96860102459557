import Card from '@material-ui/core/Card';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { usePaperCommentQuery } from '../hooks/query';

import { NotFound } from './NotFound';
import { PaperCommentCard } from './PaperCommentCard';

const Content = styled.div`
  max-width: 1000px;
  margin: 0px auto;
  padding-top: 18px;
  font-size: 20px;
`;

export const Comment: React.FC = () => {
  const { commentId } = useParams<{ commentId: string }>();
  const { error, data } = usePaperCommentQuery({
    variables: { commentID: `PaperComment:${parseInt(commentId, 10)}` },
  });

  return (
    <Content>
      {error && <NotFound />}
      {!error && data?.paperComment && (
        <Card variant="outlined">
          <PaperCommentCard
            noAction
            editorRow={30}
            paperComment={data.paperComment}
            truncatedLimit={10000000}
          />
        </Card>
      )}
    </Content>
  );
};

import React from 'react';
import styled from 'styled-components';

import { NotificationCards } from './NotificationCards';

const Content = styled.div`
  max-width: 1200px;
  margin: 0px auto;
  padding-top: 18px;
`;

export const Notifications: React.FC = () => {
  return (
    <Content>
      <NotificationCards offActions perPage={100} truncatedLimit={400} />
    </Content>
  );
};

import { ApolloError } from '@apollo/client';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { useAllCommentsQuery, useUserCommentsQuery, PagingInput } from '../hooks/query';
import { CommentConnectionType, PaperCommentType } from '../types/GraphQL';
import { idToNumber } from '../util';

import { Cards } from './Cards';
import { FullBleedButton } from './FullBleedButton';
import { NumberBadge } from './NumberBadge';
import { PaperCommentCard } from './PaperCommentCard';

const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto 20px;
`;

const getHeader = (user: 'all' | string): string => {
  if (user === 'all') {
    return 'Latest comments of all users';
  }
  return 'Comments';
};

type GetUseCommentsReturn = {
  loading: boolean;
  error: ApolloError | undefined;
  comments?: CommentConnectionType;
};
const getUseComments = (
  user: 'all' | string
): ((pagingInput: PagingInput) => GetUseCommentsReturn) => {
  if (user === 'all') {
    return (pagingInput: PagingInput): GetUseCommentsReturn => {
      const { loading, error, data } = useAllCommentsQuery({ variables: { pagingInput } });
      return { loading, error, comments: data?.allComments };
    };
  }
  return (pagingInput: PagingInput): GetUseCommentsReturn => {
    const { loading, error, data } = useUserCommentsQuery({
      variables: { pagingInput, id: user },
    });
    return { loading, error, comments: data?.user.comments };
  };
};

export const PaperCommentCards: React.FC<{
  user: 'all' | string;
  perPage?: number;
  offActions?: boolean;
  truncatedLimit?: number;
}> = ({ user, perPage = 3, offActions = false, truncatedLimit = 200 }) => {
  const history = useHistory();
  const [loadedComments, setLoadedComments] = React.useState<PaperCommentType[]>([]);
  const [cursor, setCursor] = React.useState<string | undefined>();
  const { loading, comments } = getUseComments(user)({
    first: perPage,
    after: cursor,
  });
  const paperComments = comments?.edge;
  const nextCursor = comments?.pageInfo.endCursor;
  const total = comments?.total;
  const hasNext = comments?.pageInfo.hasNextPage;
  const hasData = (paperComments ?? []).length > 0;

  const load = React.useCallback(() => {
    if (nextCursor) setCursor(nextCursor);
  }, [nextCursor]);

  React.useEffect(() => {
    setLoadedComments([...loadedComments, ...(paperComments ?? [])]);
  }, [paperComments]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Content>
      <Cards
        header={
          <>
            {getHeader(user)}
            <NumberBadge>{total}</NumberBadge>
          </>
        }
        loading={loading && !hasData}
        hasData={hasData}
        noDataText="No comment"
        loadingSkeletonSize={perPage}
        load={offActions ? load : undefined}
        last={!hasNext && offActions}
      >
        {(loadedComments ?? []).map((paperComment) => (
          <div key={paperComment.id}>
            <PaperCommentCard paperComment={paperComment} truncatedLimit={truncatedLimit} />
            <Divider />
          </div>
        ))}

        {!offActions && hasData && (
          <CardActions>
            <FullBleedButton
              color="primary"
              endIcon={<ArrowForwardIcon />}
              onClick={(): void =>
                history.push(user === 'all' ? '/comments' : `/${idToNumber(user)}/comments`)
              }
            >
              All Comments
            </FullBleedButton>
          </CardActions>
        )}
      </Cards>
    </Content>
  );
};

import { Divider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MUICard from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import Collapse from '@material-ui/core/Collapse';
import MUIFab from '@material-ui/core/Fab';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import Zoom from '@material-ui/core/Zoom';
import CommentIcon from '@material-ui/icons/Comment';
import Alert, { Color as AlertColor } from '@material-ui/lab/Alert';
import React from 'react';
import ReactGA from 'react-ga';
import styled from 'styled-components';

import {
  useSourceFromParams,
  useOriginalIDFromParams,
  useVersionFromParams,
} from '../hooks/params';
import {
  usePaperQuery,
  useCreatePaperAccessLogMutation,
  Paper_Source_Type,
  useDeletePaperTagMutation,
} from '../hooks/query';
import { isSharedTag, isTeamTag, isUserTag } from '../util';

import { CommentForm } from './CommentForm';
import { NotFound } from './NotFound';
import { PaperCardContent } from './PaperCardContent';
import { PaperCommentCard } from './PaperCommentCard';
import { Stats } from './Stats';
import { Tag } from './Tag';
import { TagAdder } from './TagAdder';
import { UserIcon } from './UserIcon';

const Content = styled.div`
  max-width: 1000px;
  margin: 0px auto;
  padding-top: 18px;
`;

const Loading = styled.div`
  margin-top: 35vh;
  text-align: center;
  color: ${({ theme }): string => theme.palette.grey[700]};
`;

const Card = styled(MUICard)`
  overflow: visible;
`;

const StatsGroup = styled.div`
  padding: 0 1rem 1rem;
  display: flex;

  & > p {
    margin-right: 1rem;
  }
`;

const Tags = styled.div`
  padding: 0 1rem 1rem;
`;

const TagChips = styled.div`
  display: flex;
  padding: 5px 0;
  flex-wrap: wrap;

  & > div {
    margin: 0 10px 10px 0;
  }
`;

const FavUsers = styled.div`
  padding: 0 1rem 1rem;
`;

const FavUsersChips = styled.div`
  padding: 5px 0;

  & > div {
    margin: 0 10px 10px 0;
  }
`;

const FabWrap = styled.div`
  position: relative;
`;

const Fab = styled(MUIFab)`
  position: absolute;
  top: -27px;
  right: 27px;
  z-index: 1000;
`;

const CommentFormWrap = styled.div`
  padding: 10px;
`;

const CloseForm = styled(Button)`
  font-size: 10px;
  margin-left: 20px;
`;

type SnackbarProps = {
  open: boolean;
  message: string;
  severity?: AlertColor;
};

const PaperInner: React.FC<{
  source: Paper_Source_Type;
  originalID: OriginalID;
  version: string | null;
}> = ({ source, originalID, version }) => {
  const paperInput = React.useMemo(
    () => ({
      source,
      originalID,
      version,
    }),
    [originalID, source, version]
  );
  const { loading, error, data, refetch } = usePaperQuery({
    variables: {
      paperInput,
    },
  });
  const [createPaperAccessLog] = useCreatePaperAccessLogMutation({
    refetchQueries: ['Paper', 'MyTags'],
  });
  const [deletePaperTag] = useDeletePaperTagMutation({ refetchQueries: ['Paper', 'MyTags'] });

  const [isOpenCommentForm, setIsOpenCommentForm] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState<SnackbarProps>({ open: false, message: '' });
  const [timer, setTimer] = React.useState<NodeJS.Timeout | null>(null);
  const paperInfo = data?.paper;

  const userTags = (paperInfo?.tags ?? [])
    .filter((tag) => isUserTag(tag.name))
    .sort((a, b) => a.name.localeCompare(b.name));
  const teamTags = (paperInfo?.tags ?? [])
    .filter((tag) => isTeamTag(tag.name))
    .sort((a, b) => a.name.localeCompare(b.name));
  const sharedTags = (paperInfo?.tags ?? [])
    .filter((tag) => isSharedTag(tag.name))
    .sort((a, b) => a.name.localeCompare(b.name));

  const comments = paperInfo?.comments ?? [];

  const showPaper = !error && !loading && paperInfo?.paper.title;

  const crawlWaiting = paperInfo && !paperInfo.paper.title;

  const closeSnackbar = React.useCallback(() => {
    setSnackbar({ open: false, message: '' });
  }, []);

  const deletePaperTagHandler = React.useCallback(
    (tagName) => {
      void deletePaperTag({ variables: { paperInput, name: tagName } });
    },
    [deletePaperTag, paperInput]
  );

  React.useEffect(() => {
    if (crawlWaiting && !timer) {
      const t = setInterval(() => {
        void refetch();
      }, 1000);
      setTimer(t);
    }
    if (!crawlWaiting && timer) {
      clearInterval(timer);
    }

    return (): void => {
      if (timer) clearInterval(timer);
    };
  }, [crawlWaiting, refetch, timer, setTimer]);

  React.useEffect(() => {
    // Queueing to crawl
    void createPaperAccessLog({
      variables: {
        paperInput,
      },
    });
  }, [createPaperAccessLog, paperInput]);

  return (
    <Content>
      {error && <NotFound />}
      {crawlWaiting && (
        <Loading>
          <CircularProgress size={50} />
          <Typography variant="h4">Crawling paper data...</Typography>
        </Loading>
      )}
      {paperInfo && showPaper && (
        <Card variant="outlined">
          <PaperCardContent
            noAction
            originalPageTitleLink
            titleVariant="h4"
            paper={paperInfo.paper}
            truncatedLimit={10000000}
            isFavorite={paperInfo.isFavorite}
            paperStatus={paperInfo.status}
          />
          <StatsGroup>
            <Stats number={paperInfo.readUsers.length} text="Readers" />
            <Stats number={paperInfo.readLaterUsers.length} text="Read Later" />
            <Stats number={paperInfo.favorites.length} text="Favorites" />
            <Stats number={comments.length} text="Comments" />
          </StatsGroup>
          <Tags>
            <Typography variant="subtitle2" color="textSecondary">
              YOUR TAGS
            </Typography>
            <TagChips>
              {userTags.map((tag) => (
                <Tag key={tag.id} tagName={tag.name} onDelete={deletePaperTagHandler} />
              ))}
              <TagAdder
                mode="user"
                paperInput={{
                  source,
                  originalID,
                  version,
                }}
                existingTags={[...userTags, ...teamTags, ...sharedTags]}
              />
            </TagChips>
          </Tags>
          <Tags>
            <Typography variant="subtitle2" color="textSecondary">
              TEAM TAGS
            </Typography>
            <TagChips>
              {teamTags.map((tag) => (
                <Tag key={tag.id} tagName={tag.name} onDelete={deletePaperTagHandler} />
              ))}
              <TagAdder
                mode="team"
                paperInput={{
                  source,
                  originalID,
                  version,
                }}
                existingTags={[...userTags, ...teamTags, ...sharedTags]}
              />
            </TagChips>
          </Tags>
          <Tags>
            <Typography variant="subtitle2" color="textSecondary">
              SHARED TAGS
            </Typography>
            <TagChips>
              {sharedTags.map((tag) => (
                <Tag key={tag.id} tagName={tag.name} onDelete={deletePaperTagHandler} />
              ))}
              <TagAdder
                mode="shared"
                paperInput={{
                  source,
                  originalID,
                  version,
                }}
                existingTags={[...userTags, ...teamTags, ...sharedTags]}
              />
            </TagChips>
          </Tags>
          {paperInfo.readUsers.length > 0 && (
            <FavUsers>
              <Typography variant="subtitle2" color="textSecondary">
                READ
              </Typography>
              <FavUsersChips>
                {[...paperInfo.readUsers].reverse().map(({ user }) => (
                  <UserIcon key={user.id} user={user} />
                ))}
              </FavUsersChips>
            </FavUsers>
          )}
          {paperInfo.readLaterUsers.length > 0 && (
            <FavUsers>
              <Typography variant="subtitle2" color="textSecondary">
                READ LATER
              </Typography>
              <FavUsersChips>
                {[...paperInfo.readLaterUsers].reverse().map(({ user }) => (
                  <UserIcon key={user.id} user={user} />
                ))}
              </FavUsersChips>
            </FavUsers>
          )}
          {paperInfo.favorites.length > 0 && (
            <FavUsers>
              <Typography variant="subtitle2" color="textSecondary">
                FAVORITE
              </Typography>
              <FavUsersChips>
                {[...paperInfo.favorites].reverse().map(({ user }) => (
                  <UserIcon key={user.id} user={user} />
                ))}
              </FavUsersChips>
            </FavUsers>
          )}
          <FabWrap>
            <Zoom in={!isOpenCommentForm}>
              <Fab
                size="large"
                color="primary"
                aria-label="add comment"
                onClick={(): void => setIsOpenCommentForm(true)}
              >
                <CommentIcon />
              </Fab>
            </Zoom>
          </FabWrap>
          {comments.length > 0 && <Divider />}
          <Collapse in={isOpenCommentForm}>
            <div>
              <Divider />
              <CommentFormWrap>
                <CommentForm
                  resize="vertical"
                  paperInput={{ source, originalID, version }}
                  formRows={10}
                  additionalButtons={
                    <CloseForm variant="outlined" onClick={(): void => setIsOpenCommentForm(false)}>
                      Close
                    </CloseForm>
                  }
                  onSuccess={(): void => {
                    setSnackbar({
                      open: true,
                      message: 'Successfully posted your comment!',
                      severity: 'success',
                    });
                    setTimeout(() => {
                      setIsOpenCommentForm(false);
                    }, 1000);
                    ReactGA.event({
                      category: 'User',
                      action: 'Post a paper comment',
                      label: 'Portal',
                    });
                  }}
                  onError={(): void => {
                    setSnackbar({
                      open: true,
                      message: 'Failed to post your comment.',
                      severity: 'error',
                    });
                  }}
                />
              </CommentFormWrap>
              <Divider />
            </div>
          </Collapse>
          {[...comments].reverse().map((comment, index) => (
            <div key={comment.id}>
              <PaperCommentCard
                noPaperTitle
                paperComment={comment}
                truncatedLimit={1000}
                paddingTop={index === 0 ? '2rem' : '1rem'}
              />
              <Divider />
            </div>
          ))}
        </Card>
      )}
      <Snackbar
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbar.open}
        onClose={closeSnackbar}
      >
        <Alert severity={snackbar.severity ?? 'info'}>{snackbar.message}</Alert>
      </Snackbar>
    </Content>
  );
};

export const Paper: React.FC = () => {
  const source = useSourceFromParams();
  const originalID = useOriginalIDFromParams();
  const version = useVersionFromParams();
  const invalidParams =
    (source === Paper_Source_Type.Biorxiv || source === Paper_Source_Type.Medrxiv) && !version;
  return (
    <>
      {!source || invalidParams ? (
        <NotFound />
      ) : (
        <PaperInner source={source} originalID={originalID} version={version} />
      )}
    </>
  );
};

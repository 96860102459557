import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { numberToId } from '../util';

import { PaperCommentCards } from './PaperCommentCards';

const Content = styled.div`
  max-width: 1200px;
  margin: 0px auto;
  padding-top: 18px;
`;

export const Comments: React.FC<{ user?: 'all' }> = ({ user }) => {
  const { userId: userIdRaw } = useParams<{ userId: string }>();
  const userId = parseInt(userIdRaw, 10);
  const userStringId = numberToId('User', userId);

  return (
    <Content>
      <PaperCommentCards
        user={user ?? userStringId}
        offActions
        perPage={100}
        truncatedLimit={400}
      />
    </Content>
  );
};

import { Button } from '@material-ui/core';
import MUICheckBox from '@material-ui/icons/CheckBox';
import MUICheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import React from 'react';
import ReactGA from 'react-ga';
import styled from 'styled-components';

import {
  useUpdatePaperFavoriteMutation,
  useDestroyPaperFavoriteMutation,
  PaperInput,
} from '../hooks/query';

const CheckBoxIcon = styled(MUICheckBox)`
  font-size: 1em;
`;

const CheckBoxOutlineIcon = styled(MUICheckBoxOutlineBlank)`
  font-size: 1em;
`;

export const FavoriteButton: React.FC<{
  paperInput: PaperInput;
  isFavorite: boolean;
  isExtension?: boolean;
  variant?: 'text' | 'contained' | 'outlined';
  onChange?: () => void;
}> = ({
  paperInput,
  isFavorite: isFavoriteGiven,
  isExtension = false,
  variant = 'contained',
  onChange,
}) => {
  const [updatePaperFavorite] = useUpdatePaperFavoriteMutation({ refetchQueries: ['Paper'] });
  const [destroyPaperFavorite] = useDestroyPaperFavoriteMutation({ refetchQueries: ['Paper'] });
  const [isFavorite, setIsFavorite] = React.useState(false);

  const toggle = React.useCallback(() => {
    const updateFav = async (): Promise<void> => {
      if (isFavorite) {
        await destroyPaperFavorite({
          variables: { paperInput },
        });
      } else {
        await updatePaperFavorite({
          variables: { paperInput },
        });
        ReactGA.event({
          category: 'User',
          action: 'Fav paper',
          label: isExtension ? 'Extension' : 'Portal',
        });
      }
      setIsFavorite((fav) => !fav);
      if (onChange) onChange();
    };
    void updateFav();
  }, [
    updatePaperFavorite,
    destroyPaperFavorite,
    setIsFavorite,
    isFavorite,
    paperInput,
    isExtension,
    onChange,
  ]);

  React.useEffect(() => {
    setIsFavorite(isFavoriteGiven);
  }, [isFavoriteGiven]);

  return (
    <Button
      variant={variant}
      disableElevation
      color="primary"
      size="small"
      startIcon={isFavorite ? <CheckBoxIcon /> : <CheckBoxOutlineIcon />}
      onClick={toggle}
    >
      Fav
    </Button>
  );
};

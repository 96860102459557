import { ApolloProvider } from '@apollo/react-hooks';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import CssBaseline from '@material-ui/core/CssBaseline';
import { StylesProvider, ThemeProvider as MaterialUIThemeProvider } from '@material-ui/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';
import {
  createGlobalStyle,
  ThemeProvider as StyledComponentsThemeProvider,
} from 'styled-components';
import 'github-markdown-css/github-markdown.css';
import 'react-calendar-heatmap/dist/styles.css';
import 'react-datepicker/dist/react-datepicker.css';

import './index.css';
import { Router as AppRouter } from './components/AppRouter';
import { GoogleAuth } from './components/GoogleAuth';
import { ScrollToTop } from './components/ScrollToTop';
import { splitPaneStyle } from './css/splitPane';
import { client } from './graphql';
import { reducer } from './reducer';
import { theme } from './theme';
import { isProduction, isDevelopment } from './util';

library.add(fab, fas, far);
dom.watch();

// Setup for Redux
const middlewares = !isProduction() ? [logger] : [];
const composeEnhancers = composeWithDevTools({});
const enhancers = !isProduction()
  ? composeEnhancers(applyMiddleware(...middlewares))
  : applyMiddleware(...middlewares);

// Instance the store object
const store = createStore(reducer, enhancers);

const head = document.querySelector('head');

const robotFontLink = document.createElement('link');
robotFontLink.rel = 'stylesheet';
if (head) head.append(robotFontLink);
robotFontLink.href = 'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap';

const GlobalStyle = createGlobalStyle`
  ${splitPaneStyle}
`;

ReactGA.initialize('UA-55135190-14', {
  debug: isDevelopment(),
  gaOptions: { cookieFlags: 'secure;samesite=none' },
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <StylesProvider injectFirst>
      <CssBaseline />
      <GlobalStyle />
      <MaterialUIThemeProvider theme={theme}>
        <StyledComponentsThemeProvider theme={theme}>
          <Provider store={store}>
            <GoogleAuth>
              <Router>
                <ScrollToTop>
                  <AppRouter />
                </ScrollToTop>
              </Router>
            </GoogleAuth>
          </Provider>
        </StyledComponentsThemeProvider>
      </MaterialUIThemeProvider>
    </StylesProvider>
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

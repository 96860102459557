import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  height: 100vh;
  background-color: #fff;
  transition: all 150ms 0s ease;
`;

export const Sidebar: React.FC = ({ children }) => {
  return <Content>{children}</Content>;
};

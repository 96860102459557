import { Chip, Avatar } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { UserContext } from '../context';
import { User } from '../hooks/query';
import { idToNumber, numberToId } from '../util';

export const UserIcon: React.FC<{
  user: User;
}> = ({ user }) => {
  const history = useHistory();

  const { session } = React.useContext(UserContext);

  return (
    <Chip
      key={user.id}
      size="small"
      color={user.id === numberToId('User', session.userId) ? 'secondary' : 'primary'}
      avatar={<Avatar />}
      label={user.userName}
      onClick={(): void => history.push(`/dashboard/${idToNumber(user.id)}`)}
      variant="outlined"
    />
  );
};

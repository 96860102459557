import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import styled from 'styled-components';

import {
  Scalars,
  useCreatePaperCommentMutation,
  useUpdatePaperCommentMutation,
  PaperInput,
  Paper_Source_Type,
} from '../hooks/query';
import { Storage, StorageKeys } from '../types/Storage';

import { TextExpander } from './TextExpander';

const TextArea = styled(TextField)`
  background-color: #f1f1f1 !important;

  & textarea {
    padding: 2px 10px;
  }
`;

const Content = styled.div`
  height: 100%;
`;

const Form = styled.form`
  height: 100%;
`;

const Preview = styled.div`
  height: calc(100% - 40px);
  overflow-y: auto;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 2px 10px;
`;

const SwitchLabel = styled(FormControlLabel)`
  height: 30px;
`;

const CommentTextArea = styled(TextArea)<{ resize: 'both' | 'vertical' | 'horizontal' | 'none' }>`
  height: calc(100% - 40px);
  font-size: 15px;

  & .MuiInput-multiline {
    height: 100%;
  }

  & .MuiInput-inputMultiline {
    height: 100%;
    resize: ${({ resize }) => resize};
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Submit = styled(Button)`
  font-size: 10px;
`;

const useComment = (
  source: Paper_Source_Type,
  originalID: OriginalID,
  updateMode: boolean,
  givenComment: string
): [string, (comment: string) => void] => {
  const [comment, setComment] = React.useState(givenComment);

  const saveComment = (cmmt: string): void => {
    setComment(cmmt);
    if (!updateMode) Storage.setByPaper(StorageKeys.EditingComment, source, originalID, cmmt);
  };

  React.useEffect(() => {
    if (!updateMode) {
      const cmmt = Storage.getByPaper(StorageKeys.EditingComment, source, originalID);
      setComment(cmmt);
    }
  }, [source, originalID, updateMode]);

  return [comment, saveComment];
};

export const CommentForm: React.FC<{
  paperInput: PaperInput;
  commentID?: Scalars['ID'];
  comment?: string;
  formRows?: number;
  additionalButtons?: React.ReactElement;
  resize?: 'both' | 'vertical' | 'horizontal' | 'none';
  onSuccess?: (newComment: string) => void;
  onError?: () => void;
}> = ({
  paperInput,
  commentID,
  comment: givenComment = '',
  formRows = 4,
  additionalButtons,
  resize = 'none',
  onSuccess,
  onError,
}) => {
  const [isPreview, setIsPreview] = React.useState(false);
  const updateMode = Boolean(commentID ?? false);
  const [createPaperComment] = useCreatePaperCommentMutation({ refetchQueries: ['Paper'] });
  const [updatePaperComment] = useUpdatePaperCommentMutation({
    refetchQueries: ['Paper', 'PaperComment'],
  });
  const [comment, setComment] = useComment(
    paperInput.source,
    paperInput.originalID,
    updateMode,
    givenComment
  );

  const onChangeComment = React.useCallback(
    (event) => {
      const { value } = event.target;
      setComment(value);
    },
    [setComment]
  );

  const postComment = React.useCallback(
    (event) => {
      event.preventDefault();
      const postApi = async (): Promise<void> => {
        const { data } = await createPaperComment({
          variables: {
            paperInput,
            comment,
          },
        });
        if (data?.createPaperComment.paperComment.id) {
          await onSuccess?.(comment);
          setComment('');
        } else {
          await onError?.();
        }
      };
      void postApi();
    },
    [paperInput, comment, setComment, createPaperComment, onSuccess, onError]
  );

  const updateComment = React.useCallback(() => {
    const update = async (): Promise<void> => {
      if (commentID) {
        const { data } = await updatePaperComment({
          variables: {
            commentID,
            comment,
          },
        });
        if (data?.updatePaperComment.paperComment.id) {
          if (onSuccess) onSuccess(comment);
        } else {
          // eslint-disable-next-line no-lonely-if
          if (onError) onError();
        }
      }
    };
    void update();
  }, [commentID, comment, updatePaperComment, onSuccess, onError]);

  return (
    <Content>
      <Form>
        {isPreview ? (
          <Preview>
            <TextExpander text={comment} pre markdown truncatedLimit={1000000} />
          </Preview>
        ) : (
          <CommentTextArea
            fullWidth
            multiline
            rows={formRows}
            value={comment}
            resize={resize}
            onChange={onChangeComment}
          />
        )}
        <Actions>
          <SwitchLabel
            control={
              <Switch
                checked={isPreview}
                onChange={() => setIsPreview((prev) => !prev)}
                name="isPreview"
                color="primary"
              />
            }
            label="Preview"
          />
          <Buttons>
            <Submit
              variant="contained"
              color="primary"
              disabled={!comment}
              onClick={updateMode ? updateComment : postComment}
            >
              Send
            </Submit>
            {additionalButtons}
          </Buttons>
        </Actions>
      </Form>
    </Content>
  );
};

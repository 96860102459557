import axiosBase from 'axios';

import { Paper_Source_Type } from './hooks/query';
import { Config } from './types/Config';
import { Storage, StorageKeys } from './types/Storage';
import { SessionUserId, Session } from './types/User';
import * as Util from './util';

const API_SERVER = Util.isProduction()
  ? process.env.REACT_APP_API_SERVER
  : process.env.REACT_APP_API_SERVER_DEV;

const axios = axiosBase.create({
  baseURL: `${API_SERVER}/api/`,
  headers: {
    'Content-Type': 'application/json',
    'X-APPLICATION-VERSION': process.env.EXTENSION_VERSION ?? '0.0.0',
  },
  responseType: 'json',
  withCredentials: true,
});

export const isError = <T>(target: T | Error): target is Error => target instanceof Error; // eslint-disable-line @typescript-eslint/no-explicit-any

interface SessionResponse {
  email: string;
  user_id: number;
}

const toSession = (response: SessionResponse): Session => ({
  email: response.email,
  userId: response.user_id,
});

export const signIn = async (
  accessToken: string
): Promise<{
  email: string;
  userId: SessionUserId;
} | null> => {
  try {
    const response = await axios.post('/v1/session', {
      access_token: accessToken,
    });
    return toSession(response.data);
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const revokeTokenToGoogle = async (
  accessToken: string
): Promise<Record<string, unknown> | null> => {
  try {
    const response = await axios.get(
      `https://accounts.google.com/o/oauth2/revoke?token=${accessToken}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const setConfig = async (config: Config): Promise<void> =>
  await Storage.setObject(StorageKeys.Config, config); // Use await to consistency of api method

export const getArxivXML = async ({
  arxivId,
}: {
  arxivId: OriginalID;
}): Promise<Document | null> => {
  try {
    const axiosXml = axiosBase.create({
      responseType: 'document',
    });
    const response = await axiosXml.get('https://export.arxiv.org/api/query', {
      params: {
        id_list: arxivId,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const cachedPdfUrl = (props: { source: Paper_Source_Type; originalID: string }): string =>
  `${API_SERVER}/api/v3/pdf/${props.source}/${props.originalID}`;

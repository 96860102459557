import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React from 'react';
import styled from 'styled-components';

import { useAuth } from '../../hooks/auth';
import { usePaperIdentifier } from '../../hooks/params';

import { BibtexDialog } from './BibtexDialog';

const SignOutMenuItem = styled(MenuItem)`
  justify-content: center;
`;

export const NavigationMenu: React.FC = () => {
  const { source, originalID, version } = usePaperIdentifier();
  const { signOut } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openBibtex, setOpenBibtex] = React.useState(false);

  const handleMenu = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleBibtexOpen = React.useCallback(() => {
    handleClose();
    setOpenBibtex(true);
  }, [handleClose]);

  const handleBibtexClose = React.useCallback(() => {
    setOpenBibtex(false);
  }, []);

  return (
    <>
      <IconButton color="inherit" onClick={handleMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleBibtexOpen}>Bibtex</MenuItem>
        <SignOutMenuItem onClick={signOut}>
          <Typography color="primary">Sign Out</Typography>
        </SignOutMenuItem>
      </Menu>
      <BibtexDialog
        paperInput={{
          source,
          originalID,
          version,
        }}
        open={openBibtex}
        onClose={handleBibtexClose}
      />
    </>
  );
};

import React from 'react';
import styled from 'styled-components';

import { RankingPaperCards } from './RankingPaperCards';

const Content = styled.div`
  max-width: 1200px;
  margin: 0px auto;
  padding-top: 18px;
`;

export const Rankings: React.FC = () => {
  return (
    <Content>
      <RankingPaperCards offActions perPage={50} truncatedLimit={400} />
    </Content>
  );
};

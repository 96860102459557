import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  width: 100%;
`;

const Bars = styled.div`
  display: flex;
  width: 100%;
`;

const Bar = styled.div`
  height: 15px;
  font-size: 12px;
  font-weight: bold;
  line-height: 12px;
  color: ${({ theme }): string => theme.palette.common.white};
`;

const ReadBar = styled(Bar)`
  text-align: right;
  background-color: ${({ theme }): string => theme.palette.primary.light};
  opacity: 0.9;
`;

const ReadLaterBar = styled(Bar)`
  background-color: ${({ theme }): string => theme.palette.primary.light};
  opacity: 0.6;
`;

const Legends = styled.div`
  display: flex;
  width: 100%;
`;

const Legend = styled.div`
  margin-right: 20px;
  color: ${({ theme }): string => theme.palette.grey['700']};

  &:before {
    content: '';
    display: inline-block;
    margin-right: 2px;
    width: 10px;
    height: 10px;
  }
`;

const ReadLegend = styled(Legend)`
  &:before {
    background-color: ${({ theme }): string => theme.palette.primary.light};
    opacity: 0.9;
  }
`;

const ReadLaterLegend = styled(Legend)`
  &:before {
    background-color: ${({ theme }): string => theme.palette.primary.light};
    opacity: 0.6;
  }
`;

export const StatusStackedBar: React.FC<{ readsCount: number; readLatersCount: number }> = ({
  readsCount,
  readLatersCount,
}) => {
  const total = readsCount + readLatersCount;

  if (readsCount + readLatersCount === 0) return null;
  return (
    <Content>
      <>
        <Bars>
          <ReadBar style={{ width: `${(100 * readsCount) / total}%` }} />
          <ReadLaterBar style={{ width: `${(100 * readLatersCount) / total}%` }} />
        </Bars>
        <Legends>
          <ReadLegend>Read</ReadLegend>
          <ReadLaterLegend>Read Later</ReadLaterLegend>
        </Legends>
      </>
    </Content>
  );
};

import { Box } from '@material-ui/core';
import React from 'react';
import ReactGA from 'react-ga';
import { useLocation, Route, Switch, Redirect } from 'react-router-dom';
import styled from 'styled-components';

import { UserContext } from '../context';

import { AccessLogs } from './AccessLogs';
import { Comment } from './Comment';
import { Comments } from './Comments';
import { DashBoard } from './DashBoard';
import { Navigation } from './Navigation';
import { Notifications } from './Notifications';
import { Paper } from './Paper';
import { Papers } from './Papers';
import { Rankings } from './Rankings';
import { Statistics } from './Statistics';
import { TagPapers } from './TagPapers';
import { Tags } from './Tags';

const Content = styled.div`
  padding: 0 8px;
`;

export const App: React.FC<{ redirectToDashboard?: boolean }> = ({
  redirectToDashboard = false,
}) => {
  const location = useLocation();
  const { session } = React.useContext(UserContext);

  React.useEffect(() => {
    ReactGA.set({ userId: session.email ?? 'unknown' });
    ReactGA.pageview(location.pathname + location.search + location.hash);
  }, [location, session.email]);

  return (
    <Content>
      <Navigation />
      <Box my={6}>
        <Switch>
          <Route exact path="/">
            {redirectToDashboard && <Redirect to={`/dashboard/${session.userId}`} />}
          </Route>
          <Route
            exact
            path="/dashboard/:userId"
            component={(): React.ReactElement => <DashBoard />}
          />
          <Route
            exact
            path="/:userId/statistics"
            component={(): React.ReactElement => <Statistics />}
          />
          <Route
            exact
            path="/me/notifications"
            component={(): React.ReactElement => <Notifications />}
          />
          <Route exact path="/me/accesslogs" component={(): React.ReactElement => <AccessLogs />} />
          <Route exact path="/:userId/papers" component={(): React.ReactElement => <Papers />} />
          <Route
            exact
            path="/:userId/comments"
            component={(): React.ReactElement => <Comments />}
          />
          <Route
            exact
            path="/comments"
            component={(): React.ReactElement => <Comments user="all" />}
          />
          <Route
            exact
            path="/comments/:commentId"
            component={(): React.ReactElement => <Comment />}
          />
          <Route
            exact
            path="/tags/:tagName/papers"
            component={(): React.ReactElement => <TagPapers />}
          />
          <Route exact path="/tags" component={(): React.ReactElement => <Tags />} />
          <Redirect exact from="/papers/:originalID" to="/papers/arxiv/:originalID/noVersion" />
          <Route
            exact
            path={['/papers/:source/:originalID', '/papers/:source/:originalID/:version']}
            component={(): React.ReactElement => <Paper />}
          />
          <Route exact path="/rankings" component={(): React.ReactElement => <Rankings />} />
        </Switch>
      </Box>
    </Content>
  );
};

import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { useMyNotificationQuery } from '../hooks/query';

import { Cards } from './Cards';
import { FullBleedButton } from './FullBleedButton';
import { NotificationCard } from './NotificationCard';

const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto 20px;
`;

export const NotificationCards: React.FC<{
  perPage?: number;
  offActions?: boolean;
  truncatedLimit?: number;
}> = ({ perPage = 3, offActions = false, truncatedLimit = 200 }) => {
  const history = useHistory();
  const [cursor, setCursor] = React.useState<string | undefined>();
  const { loading, data } = useMyNotificationQuery({
    variables: {
      pagingInput: { first: perPage, after: cursor },
    },
  });
  const nextCursor = data?.me.notifications.pageInfo.endCursor;
  const notifications = data?.me.notifications.edge ?? [];
  const hasData = notifications.length > 0;

  const load = React.useCallback(() => {
    if (nextCursor) setCursor(nextCursor);
  }, [nextCursor]);

  return (
    <Content>
      <Cards
        header="Notifications"
        loading={loading}
        hasData={hasData}
        noDataText="No notification"
        loadingSkeletonSize={perPage}
        load={offActions ? load : undefined}
        last={!nextCursor && offActions}
      >
        {notifications.map((notification, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={i}>
            <NotificationCard notification={notification} truncatedLimit={truncatedLimit} />
            <Divider />
          </div>
        ))}

        {!offActions && hasData && (
          <CardActions>
            <FullBleedButton
              color="primary"
              endIcon={<ArrowForwardIcon />}
              onClick={(): void => history.push('/me/notifications')}
            >
              All Notifications
            </FullBleedButton>
          </CardActions>
        )}
      </Cards>
    </Content>
  );
};

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import MuiIconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import React from 'react';
import ReactGA from 'react-ga';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce';

import { UserContext } from '../context';
import { useIsMyComment } from '../hooks';
import {
  useDeletePaperCommentFavoriteMutation,
  useDeletePaperCommentMutation,
  useUpdatePaperCommentFavoriteMutation,
} from '../hooks/query';
import { toPapers } from '../routing';
import { PaperCommentType } from '../types/GraphQL';
import * as Util from '../util';

import { Anchor } from './Anchor';
import { CommentForm } from './CommentForm';
import { ConfirmationModal } from './ConfirmationModal';
import { StopPropagation } from './StopPropagation';
import { SwitchableCardActionArea } from './SwitchableCardActionArea';
import { TextExpander } from './TextExpander';

const IconButtons = styled.div`
  margin-top: 15px;
  display: none;

  & svg {
    color: ${({ theme }): string => theme.palette.grey[400]};
  }
`;

const IconButton = styled(MuiIconButton)`
  margin-right: 5px;
` as typeof MuiIconButton;

const FavoriteIconButton = styled(MuiIconButton)`
  color: #e43f4d;
` as typeof MuiIconButton;

const CardInner = styled.div<{ paddingTop: string }>`
  position: relative;
  padding: 1rem 0.5rem 0.5rem;
  padding-top: ${({ paddingTop }): string => paddingTop};

  &:hover ${IconButtons} {
    display: block;
  }
`;

const PaperTitle = styled.div`
  width: calc(100% - 20px);
  margin-top: 0.5rem;
  font-size: 0.9rem;
`;

const CommentFormWrap = styled.div`
  margin-top: 0.5rem;
`;

const CommentMenu = styled.div`
  position: absolute;
  top: 2px;
  right: 0;
`;

const CommentContent = styled(Typography)`
  padding: 0.5rem;
` as typeof Typography;

const CommentMeta = styled.div`
  margin-top: 10px;
  display: flex;
`;

const CommentMetaLeft = styled.div`
  flex: 1;
`;

const CommentMetaRight = styled.div`
  text-align: right;
`;

const FavoriteButton = styled.div`
  display: flex;
  align-items: center;
`;

const UserName = styled(Chip)`
  margin-bottom: 0.5rem;
`;

const CreatedAt = styled(Typography)`
  margin-bottom: 0;
  font-size: 0.7rem;
`;

const Quotation = styled.div`
  margin-top: 20px;
  background: #eee;
  padding: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const CloseForm = styled(Button)`
  font-size: 10px;
  margin-left: 20px;
`;

export const PaperCommentCard: React.FC<{
  paperComment: PaperCommentType;
  truncatedLimit?: number;
  noPaperTitle?: boolean;
  noAction?: boolean;
  openLinkNewTab?: boolean;
  paddingTop?: string;
  editorRow?: number;
  onEdit?: () => void;
  onDelete?: () => void;
}> = ({
  paperComment: { id: commentID, paper, comment: givenComment, favorites, user, createdAt },
  truncatedLimit = 200,
  noPaperTitle = false,
  noAction = false,
  openLinkNewTab = false,
  paddingTop = '0',
  editorRow = 10,
  onEdit = (): void => {},
  onDelete = (): void => {},
}) => {
  const { session } = React.useContext(UserContext);
  const history = useHistory();
  const [deletePaperComment] = useDeletePaperCommentMutation({ refetchQueries: ['Paper'] });
  const [updatePaperCommentFavorite] = useUpdatePaperCommentFavoriteMutation({
    refetchQueries: ['Paper', 'PaperComment'],
  });
  const [deletePaperCommentFavorite] = useDeletePaperCommentFavoriteMutation({
    refetchQueries: ['Paper', 'PaperComment'],
  });
  const isMyComment = useIsMyComment();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [newComment, setNewComment] = React.useState('');
  const [editing, setEditing] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);

  const [comment] = useDebounce(newComment || givenComment, 10);

  const [isFavorite, setIsFavorite] = React.useState<boolean>(
    favorites.filter((fav) => fav.user.id === Util.numberToId('User', session.userId)).length === 1
  );
  const [favoriteSize, setFavoriteSize] = React.useState(favorites.length);

  const handleClick = React.useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const deleteComment = React.useCallback(() => {
    (async (): Promise<void> => {
      await deletePaperComment({ variables: { commentID } });

      onDelete();
      // TODO: Error handling
    })();
  }, [commentID, deletePaperComment, onDelete]);

  const toggleFavorite = React.useCallback(() => {
    (async (): Promise<void> => {
      if (isFavorite) {
        await deletePaperCommentFavorite({ variables: { commentID } });
        setIsFavorite(false);
        setFavoriteSize((prev) => prev - 1);
      } else {
        await updatePaperCommentFavorite({ variables: { commentID } });
        setIsFavorite(true);
        setFavoriteSize((prev) => prev + 1);
        ReactGA.event({
          category: 'User',
          action: 'Fav a paper comment',
          label: 'All',
        });
      }
      // TODO: Error handling
    })();
  }, [commentID, deletePaperCommentFavorite, isFavorite, updatePaperCommentFavorite]);

  return (
    <div>
      <SwitchableCardActionArea
        noAction={noAction || editing}
        onClick={(event): void => {
          const target = event.target as HTMLElement;
          if (target.tagName !== 'A') {
            if (openLinkNewTab) {
              Util.openPortal(`/comments/${Util.idToNumber(commentID)}`);
            } else {
              history.push(`/comments/${Util.idToNumber(commentID)}`);
            }
          }
        }}
      >
        <CardInner paddingTop={paddingTop}>
          {!noPaperTitle && (
            <PaperTitle>
              <Anchor to={toPapers(paper.source, paper.originalID, paper.version)}>
                {`>> ${paper.title}`}
              </Anchor>
            </PaperTitle>
          )}
          {editing ? (
            <StopPropagation>
              <CommentFormWrap>
                <CommentForm
                  resize="vertical"
                  paperInput={{
                    source: paper.source,
                    originalID: paper.originalID,
                    version: paper.version,
                  }}
                  commentID={commentID}
                  comment={comment}
                  formRows={editorRow}
                  additionalButtons={
                    <CloseForm variant="outlined" onClick={(): void => setEditing(false)}>
                      Close
                    </CloseForm>
                  }
                  onSuccess={(cmmt): void => {
                    onEdit();
                    setNewComment(cmmt);
                    setEditing(false);
                  }}
                />
              </CommentFormWrap>
            </StopPropagation>
          ) : (
            <>
              <CommentMenu>
                {isMyComment(user) && (
                  <IconButton
                    aria-controls="comment-menu"
                    aria-haspopup="true"
                    component="span"
                    size="small"
                    onClick={(event: React.MouseEvent): void => {
                      event.stopPropagation();
                      handleClick(event);
                    }}
                  >
                    <ExpandMoreIcon fontSize="small" />
                  </IconButton>
                )}
                <StopPropagation>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={(event: React.MouseEvent): void => {
                        event.stopPropagation();
                        handleClose();
                        setEditing(true);
                      }}
                    >
                      Edit
                    </MenuItem>
                    <MenuItem
                      onClick={(event: React.MouseEvent): void => {
                        event.stopPropagation();
                        handleClose();
                        setDeleting(true);
                      }}
                    >
                      Delete
                    </MenuItem>
                  </Menu>
                </StopPropagation>
              </CommentMenu>
              <CommentContent color="textPrimary" component="div">
                <TextExpander text={comment} truncatedLimit={truncatedLimit} pre markdown />
              </CommentContent>
              <CommentMeta>
                <CommentMetaLeft>
                  <StopPropagation>
                    <FavoriteButton>
                      <FavoriteIconButton
                        aria-label="favorite"
                        component="span"
                        onClick={toggleFavorite}
                      >
                        {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                      </FavoriteIconButton>
                      <Typography variant="body2" color="textSecondary">
                        {favoriteSize}
                      </Typography>
                    </FavoriteButton>
                  </StopPropagation>
                </CommentMetaLeft>
                <CommentMetaRight>
                  <UserName
                    size="small"
                    color={isMyComment(user) ? 'secondary' : 'primary'}
                    avatar={<Avatar />}
                    label={user?.userName ?? 'unknown'}
                    onClick={(event): void => {
                      event.stopPropagation();
                      if (openLinkNewTab) {
                        Util.openPortal(`/dashboard/${Util.idToNumber(user?.id)}`);
                      } else {
                        history.push(`/dashboard/${Util.idToNumber(user?.id)}`);
                      }
                    }}
                    variant="outlined"
                  />
                  <CreatedAt color="textSecondary">
                    Created at {Util.toLocaleString(createdAt)}
                  </CreatedAt>
                </CommentMetaRight>
              </CommentMeta>
            </>
          )}
        </CardInner>
        <StopPropagation>
          <ConfirmationModal
            open={deleting}
            title="Delete your comment"
            message={
              <div>
                <div>Are you sure to delete below comment:</div>
                <Quotation>{comment}</Quotation>
              </div>
            }
            onOk={(): void => {
              setDeleting(false);
              deleteComment();
            }}
            onCancel={(): void => setDeleting(false)}
          />
        </StopPropagation>
      </SwitchableCardActionArea>
    </div>
  );
};
